import moment from 'moment-timezone';
import { notification } from 'antd';
import {
  fetchDowntimeLog,
  addReason,
  mergeDowntime,
  demergeDowntime,
  generateStatsForTime,
  sendSapNotification,
  alreadyAddedStopages,
} from '@/services/firebase';
import { list, create, update, remove } from '@/services/firestore';
import { isPlannedowntimeLog } from '../utils/downtime';

const namespace = 'downtimeLog';
export default {
  namespace,

  state: {
    selectedDowntime: false,
    loadingList: false,
    list: [],
  },

  effects: {
    *list({ payload }, { call, put, takeEvery, take, select }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/list`,
      });

      yield put({
        type: 'save',
        payload: { loadingList: true },
      });
      const machine = yield select((state) => state.productionLine.machine);
      let productionLine = yield select((state) => state.productionLine.current)
      // create subscription
      payload.params.machine = machine ? machine.machineName : null
      const service = yield call(list, {
        module: namespace,
        orderBy: ['started', 'desc'],
        ...payload,
      });

      function* push(response) {
        let list = null
        console.log(response)

        if (response.operationType) {
          if (!((moment(response.started).isAfter(moment(payload.conditions.started['$gte'])) &&
            moment(response.started).isBefore(moment(payload.conditions.started['$lte']))) ||
            response.operationType == 'delete'))
            return

          response.id = response['_id']
          list = yield select(state => state.downtimeLog.list);
          list = JSON.parse(JSON.stringify(list))

          let index = list.findIndex(x => x.id == response.id)
          if (index > -1) {
            if (response.operationType == 'delete')
              list.splice(index, 1);
            else {
              delete response['operationType']
              list[index] = response
            }
          }
          else {
            delete response['operationType']
            let insertLast = true
            for (let i in list) {
              if (moment(list[i].started).isBefore(moment(response.started))) {
                insertLast = false
                list.splice(i, 0, response);
                break
              }
            }
            if (insertLast)
              list.push(response)
          }
        }
        else
          list = Array.isArray(response) ? response : [];
        let stopTime = machine ? (machine.stopTime ? machine.stopTime.reason : 3) : (productionLine.stopTime != null ? productionLine.stopTime.reason : 3)
        const filteredList = list.filter(
          (item) =>
            (!item.ended &&
              moment.duration(moment(new Date()).diff(moment(item.started).toDate())).asMinutes() > stopTime) ||
            item.type === 'disconnectLog' || // either current downtime
            (item.ended &&
              moment.duration(moment(item.ended).diff(moment(item.started).toDate())).asMinutes() >
              stopTime) // OR more than 3 minutes
        );


        yield put({
          type: 'saveList',
          payload: filteredList,
        });
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/list`);
      service.close();
      yield put({
        type: 'clearList',
      });
    },

    *sendSapNotificationToHilal({payload}, {call, put, select}){
      console.log("calling::");
      const response = yield call(sendSapNotification, {
        ...payload,
        collecitonId: namespace,
      });
    },
    // Add reason to downtime log
    *updateLogWithReason({ payload }, { call, put, select }) {

      try {
        const { logs, currentProductionLine } = yield select((state) => ({
          logs: state[namespace].list
        }));
        const plant = yield select((state) => state.plant.current);

        let finalPayload = payload;
        //let onceUsed = false;
        const logToChange = logs.find((log) => log.id === payload.id);
        const machine = yield select((state) => state.productionLine.machine);
        const productionLine = yield select((state) => state.productionLine.current);
        let plannedDowntimes = productionLine.plannedDowntimes?productionLine.plannedDowntimes[machine ? machine.machineName : "Default"]:null;
        if (plannedDowntimes && plannedDowntimes.length > 0 && payload.comment != "Planned Downtime") {
          let plannedStoppages = plannedDowntimes.filter(x => {
            return x.maxMinutes
          });
          if (plannedStoppages.length > 0) {
            let { reason, started, ended,onceUsed=false } = payload
            let filterStoppage = plannedStoppages.find(ps => ps.reason.toLowerCase() == reason.toLowerCase())
            
            if (filterStoppage ) {
              if(filterStoppage.onceUsePerShift){
                //const onceUsed = yield call(alreadyAddedStopages,{reason,started,productionLineId:productionLine.id,machine:machine?machine.machineName:null})
                //console.log(onceUsed,'isAlreadyAddedStopages')
                if(!onceUsed){
                let duration = moment.duration(moment(ended).diff(started)).asMinutes();
                if (duration > filterStoppage.maxMinutes) {
  
                  let demergeTime = moment(started).add(filterStoppage.maxMinutes, 'minutes').toDate()
  
                  let before = {
                    ...payload,
                    _id: undefined,
                    started: moment(started).toDate(),
                    ended: demergeTime,
                    comment: "Planned Downtime",
                    reason: payload.reason
                  }
                  let payloadAfter =payload
                  delete payloadAfter.reason
  
                  let after = {
                    ...payloadAfter,
                    _id: undefined,
                    started: demergeTime,
                    ended: moment(ended).toDate()
                  }
  
                  const response = yield call(demergeDowntime, {
                    downtimeBefore: before,
                    downtimeAfter: after,
                    downtime: payload,
                    prodId: productionLine.id,
                    plantId: plant.id,
                    collecitonId: machine ? `${machine.machineName} ${namespace}` : namespace,
                  });
                  // yield put({ type: 'generateStatsForTime', payload: before });
  
                  let desc = 'For current downtime';
                  if (payload.ended) {
                    desc =
                      'For ' +
                      moment(payload.started).format('h:mm:ss a') +
                      ' - ' +
                      moment(payload.ended).format('h:mm:ss a');
                  }
                  if (response) {
                    notification['success']({
                      message: 'Stoppage reason logged !',
                      description: payload.reason + ' - ' + desc,
  
                    });
                  }
  
  
                } else {
                  finalPayload.comment = "Planned Downtime";
                  yield* addPlannedReason(call, finalPayload, payload, logToChange, put);
  
                }
              }
              else
              {
                const response = yield call(addReason, {
                  ...payload,
                  collecitonId: namespace,
                });
              }
              }
              else{
                  let duration = moment.duration(moment(ended).diff(started)).asMinutes();
                  if (duration > filterStoppage.maxMinutes) {
    
                    let demergeTime = moment(started).add(filterStoppage.maxMinutes, 'minutes').toDate()
    
                    let before = {
                      ...payload,
                      _id: undefined,
                      started: moment(started).toDate(),
                      ended: demergeTime,
                      comment: "Planned Downtime",
                      reason: payload.reason
                    }
                    let payloadAfter =payload
                    delete payloadAfter.reason
    
                    let after = {
                      ...payloadAfter,
                      _id: undefined,
                      started: demergeTime,
                      ended: moment(ended).toDate()
                    }
    
                    const response = yield call(demergeDowntime, {
                      downtimeBefore: before,
                      downtimeAfter: after,
                      downtime: payload,
                      prodId: productionLine.id,
                      plantId: plant.id,
                      collecitonId: machine ? `${machine.machineName} ${namespace}` : namespace,
                    });
                    // yield put({ type: 'generateStatsForTime', payload: before });
    
                    let desc = 'For current downtime';
                    if (payload.ended) {
                      desc =
                        'For ' +
                        moment(payload.started).format('h:mm:ss a') +
                        ' - ' +
                        moment(payload.ended).format('h:mm:ss a');
                    }
                    if (response) {
                      notification['success']({
                        message: 'Stoppage reason logged !',
                        description: payload.reason + ' - ' + desc,
    
                      });
                    }
    
    
                  } else {
                    finalPayload.comment = "Planned Downtime";
                    yield* addPlannedReason(call, finalPayload, payload, logToChange, put);
    
                  }
              }


            } else {
              const response = yield call(addReason, {
                ...payload,
                collecitonId: namespace,
              });
              if (response) {
                //if changing to "planned downtime" or changing from "planned downtime"
                //in both cases regenerate stats for that shift
                if (isPlannedowntimeLog(payload) || isPlannedowntimeLog(logToChange ||{})|| payload.batchNumber) {
                  // yield put({ type: 'generateStatsForTime', payload });
                } else {
                  let desc = 'For current downtime';
                  console.log(payload);
                  if (payload.ended) {
                    desc =
                      'For ' +
                      moment(moment(payload.started).toDate()).format('h:mm:ss a') +
                      ' - ' +
                      moment(moment(payload.ended).toDate()).format('h:mm:ss a');
                  }

                  notification['success']({
                    message: 'Stoppage reason logged !',
                    description: payload.reason + ' - ' + desc,
                  });
                }
              }

            }
          } else {
            const response = yield call(addReason, {
              ...payload,
              collecitonId: namespace,
            });
            if (response) {
              //if changing to "planned downtime" or changing from "planned downtime"
              //in both cases regenerate stats for that shift
              if (isPlannedowntimeLog(payload) || isPlannedowntimeLog(logToChange || {})|| payload.batchNumber) {
                // yield put({ type: 'generateStatsForTime', payload });
              } else {
                let desc = 'For current downtime';
                if (payload.ended) {
                  desc =
                    'For ' +
                    moment(payload.started).format('h:mm:ss a') +
                    ' - ' +
                    moment(payload.ended).format('h:mm:ss a');
                }

                notification['success']({
                  message: 'Stoppage reason logged !',
                  description: payload.reason + ' - ' + desc,
                });
              }
            }

          }

        }


        else {

          const response = yield call(addReason, {
            ...payload,
            collecitonId: namespace,
          });
          if (response) {
            //if changing to "planned downtime" or changing from "planned downtime"
            //in both cases regenerate stats for that shift
            if (isPlannedowntimeLog(payload) || isPlannedowntimeLog(logToChange || {})|| payload.batchNumber) {
              // yield put({ type: 'generateStatsForTime', payload });
            } else {
              let desc = 'For current downtime';
              if (payload.ended) {
                desc =
                  'For ' +
                  moment(payload.started).format('h:mm:ss a') +
                  ' - ' +
                  moment(payload.ended).format('h:mm:ss a');
              }

              notification['success']({
                message: 'Stoppage reason logged !',
                description: payload.reason + ' - ' + desc,
              });
            }
          }
        }
        console.log('here');
        yield put({ type: 'generateStatsForTime', payload });
      }
      catch (error) {
        console.log("updateLogError",error)
      }
    },

    *generateStatsForTime({ payload }, { call, select }) {
      try {
        const currentProductionLine = yield select((state) => state.productionLine.current);
        const machine = yield select((state) => state.productionLine.machine);
        notification.info({
          message: 'Planned Downtime Logged!',
          description: 'Excluding DOWNTIME from OEE calculation',
        });

        const time = moment(payload.ended || payload.started).toDate();
        const stats = yield call(generateStatsForTime, {
          productionLineId: currentProductionLine.id,
          time,
          machine: machine ? machine.machineName : null,
          statsGenerate:true,
        });

        if (!stats) {
          notification.error({
            message: 'Error Adjusting OEE!',
            description: 'An unknown error occurred!',
          });
        } else {
          notification.success({
            message: 'OEE Adjusted!',
            description: 'Excluded DOWNTIME from OEE calculation',
          });
        }
      } catch (error) {
        console.log(error);
      }

    },
    *merge({ payload }, { call, put, select }) {
      const currentProductionLine = yield select((state) => state.productionLine.current);
      const plant = yield select((state) => state.plant.current);
      const machine = yield select((state) => state.productionLine.machine);
      const response = yield call(mergeDowntime, {
        downtimeBefore: payload.before,
        downtimeAfter: payload.after,
        prodId: currentProductionLine.id,
        plantId: plant.id,
        collecitonId: machine ? `${machine.machineName} ${namespace}` : namespace,
      });
      if (response) {
        notification['success']({
          message: 'Downtimes Merged !',
        });
      }
    },
    *demerge({ payload }, { call, put, select }) {
      const currentProductionLine = yield select((state) => state.productionLine.current);
      const plant = yield select((state) => state.plant.current);
      const machine = yield select((state) => state.productionLine.machine);
      payload.prodId = currentProductionLine.id;
      const response = yield call(demergeDowntime, {
        downtimeBefore: payload.before,
        downtimeAfter: payload.after,
        downtime: payload.downtime,
        prodId: currentProductionLine.id,
        plantId: plant.id,
        collecitonId: machine ? `${machine.machineName} ${namespace}` : namespace,
      });
      if (response) {
        notification['success']({
          message: 'Downtimes De Merged !',
        });
      }
    },
    *unsubscribe(_, { put }) {
      yield put({
        type: `clear:${namespace}/list`,
      });
      yield put({
        type: `clear:${namespace}/fetchList`,
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    // set last downtime as current selected if its unended and not reason specified
    setDefaultDowntime(state) {
      // set last downtime
      const lastDowntime = state.list.length > 0 ? state.list[0] : false;
      return {
        ...state,
        selectedDowntime: lastDowntime && !lastDowntime.reason ? lastDowntime : false,
      };
    },
    saveSelectedDowntime(state, { payload }) {
      return {
        ...state,
        selectedDowntime: payload,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
        loadingList: false,
      };

    },
    clearList(state) {
      return {
        ...state,
        loadingList: false,
        list: [],
      };
    },
  },
};
function* addPlannedReason(call, finalPayload, payload, logToChange, put) {
  const response = yield call(addReason, {
    ...finalPayload,
    collecitonId: namespace,
  });
  if (response) {
    //if changing to "planned downtime" or changing from "planned downtime"
    //in both cases regenerate stats for that shift
    if (isPlannedowntimeLog(payload) || isPlannedowntimeLog(logToChange || {}) || payload.batchNumber) {
      //yield put({ type: 'generateStatsForTime', payload });
    } else {
      let desc = 'For current downtime';
      if (payload.ended) {
        desc =
          'For ' +
          moment(payload.started).format('h:mm:ss a') +
          ' - ' +
          moment(payload.ended).format('h:mm:ss a');
      }

      notification['success']({
        message: 'Stoppage reason logged !',
        description: payload.reason + ' - ' + desc,
      });
    }
  }
}

