import { currentShift, getShiftByRange, stringHours2NumHours } from '@/utils/shifts';
import { notification } from 'antd';
import moment from 'moment-timezone';
import { list } from '@/services/firestore';

const namespace = 'shifts';
export default {
  namespace,

  state: {
    currentShift: null,
    availableShifts: [],
    selectedDate: moment(new Date()),
    selectedShift: null,
    selectedDateForHilal:moment(new Date()),
    selectedShiftForHilal:null,
    availableShiftsForHilal: [],
    loading:true,


  },

  effects: {
    *setCurrentShift({ _ }, { put, select }) {
      try {
        const productionLine = yield select(state => state.productionLine.current);
        const machine = yield select(state => state.productionLine.machine);
        const  currentShiftData = currentShift(machine ? machine.shifts : productionLine.shifts);
        yield put({
              type: 'save',
              payload: {
                currentShift: {
                  ...currentShiftData,
                                    // statsId: `${currentShift.range}-${selectedDate.format('YYYY-MM-DD')}`,
                  },
                },
              });
           
        
      } catch (error) {
        console.log('setCurrentShift error', error);
      }
    },
    *getMergedShiftsForHilal({ payload},{put, select}) {
      const productionLine = 'line-4_ii4hb';

      yield put({
        type: 'save',
        payload: {
          selectedDateForHilal: payload,
        },
      });

      yield put({
        type: 'getAvailableShiftsForHilal',
      });
    },
    *getShiftsForDate({ payload }, { put, select }) {
      
      const productionLine = yield select(state => state.productionLine.current);

      yield put({
        type: 'save',
        payload: {
          selectedDate: payload,
        },
      });

      yield put({
        type: 'getAvailableShifts',
      });
    },
    *getAvailableShifts({ payload }, { call, put, select }) {
      yield put({
        type: 'save',
        payload: { loading: true },
      });
      const productionLine = yield select(state => state.productionLine.current);
      const machine = yield select(state => state.productionLine.machine);
      const selectedDate = yield select(state => state.shifts.selectedDate);
      let selectedShift = null;
      let availableShifts = [];

      // check if given date is today or before
      if (selectedDate.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
        const shifts = machine ? machine.shifts : yield select(state => state.productionLine.current.shifts);
        availableShifts = shifts.reduce((total, item) => {
          return total.concat({
            ...item,
            statsId: `${productionLine.id}-${machine ? `${machine.machineName}-` : ''}${item.range}-${selectedDate.format('YYYY-MM-DD')}`,
          });
        }, []);
        // selected shift same as current shift
        selectedShift = yield select(state => state.shifts.currentShift);
        selectedShift = availableShifts.find(item => item.range === selectedShift.range);
      } else {
        let plant = yield select(state => state.plant.current);

        // if before
        let stats = yield call(list, {
          module: 'stats',
          orderBy: ['time', 'desc'],
          params: {
            productionLineId: productionLine.id,
            machine: machine ? machine.machineName : null
          },
          conditions: {
            'shift.fromTime' : 
            {
              $gte: moment(selectedDate)
              .startOf('day')
              .toDate(),
              $lte: 
              moment(selectedDate)
              .endOf('day')
              .toDate(),
            },
            plantId: plant.id
          },
          attributes: {
            "_id" : 1,
           "shift" : 1
          },
          stream: false,
        });
        // stats = stats.filter(x => x.machine == (machine ? machine.machineName : null))
        availableShifts = stats.reduce((total, item) => {
          return total.concat({
            ...item.shift,
            statsId: item.id,
          });
        }, []);
        // console.log(availableShifts)
        const currentShiftData = currentShift(availableShifts);
        selectedShift = currentShiftData
          ? currentShiftData
          : availableShifts.length > 0
          ? availableShifts[0]
          : null;
      }

      availableShifts = availableShifts.sort(
        (curr, next) => stringHours2NumHours(curr.from) - stringHours2NumHours(next.from)
      );
      yield put({
        type: 'save',
        payload: {
          availableShifts,
          selectedShift,
          loading: false,
        },
      });
    },
    *getAvailableShiftsForHilal({ payload }, { call, put, select }) {
      const productionLine = {id:'line-4_ii4hb'};
      const machine = yield select(state => state.productionLine.machine);
      const selectedDate = yield select(state => state.shifts.selectedDateForHilal);
      let selectedShiftForHilal = null;
      let availableShiftsForHilal = [];

      // check if given date is today or before
      if (selectedDate.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
        const shifts = machine ? machine.shifts : yield select(state => state.productionLine.current.shifts);
        availableShiftsForHilal = shifts.reduce((total, item) => {
          return total.concat({
            ...item,
            statsId: `${productionLine.id}-${machine ? `${machine.machineName}-` : ''}merged-${item.range}-${selectedDate.format('YYYY-MM-DD')}`,
          });
        }, []);
        // selected shift same as current shift
        selectedShiftForHilal = yield select(state => state.shifts.currentShift);
        selectedShiftForHilal = availableShiftsForHilal.find(item => item.range === selectedShiftForHilal.range);
      } else {
        let plant = yield select(state => state.plant.current);

        // if before
        let stats = yield call(list, {
          module: 'mergeStats',
          orderBy: ['time', 'desc'],
          params: {
            productionLineId: productionLine.id
          },
          conditions: {
            'shift.fromTime' : 
            {
              $gte: moment(selectedDate)
              .startOf('day')
              .toDate(),
              $lte: 
              moment(selectedDate)
              .endOf('day')
              .toDate(),
            },
            plantId: plant.id
          },
          attributes: {
            "_id" : 1,
           "shift" : 1
          },
          stream: false,
        });
        // stats = stats.filter(x => x.machine == (machine ? machine.machineName : null))
        availableShiftsForHilal = stats.reduce((total, item) => {
          return total.concat({
            ...item.shift,
            statsId: item.id,
          });
        }, []);
        // console.log(availableShifts)
        const currentShiftData = currentShift(availableShiftsForHilal);
        selectedShiftForHilal = currentShiftData
          ? currentShiftData
          : availableShiftsForHilal.length > 0
          ? availableShiftsForHilal[0]
          : null;
      }

      availableShiftsForHilal = availableShiftsForHilal.sort(
        (curr, next) => stringHours2NumHours(curr.from) - stringHours2NumHours(next.from)
      );
      yield put({
        type: 'save',
        payload: {
          availableShiftsForHilal,
          selectedShiftForHilal,
        },
      });
    },
    *setSelectedShiftByByRange({ payload }, { put, select }) {
      try {
        const availableShifts = yield select(state => state.shifts.availableShifts);
        const selectedShift = availableShifts.find(item => item.range === payload);
        yield put({
          type: 'save',
          payload: {
            selectedShift: selectedShift ? selectedShift : null,
          },
        });
      } catch (error) {
        console.log('setSelectedShiftByByRange error', error);
      }
    },
    *setSelectedShiftByByRangeForHilal({ payload }, { put, select }) {
      try {
        const availableShiftsForHilal = yield select(state => state.shifts.availableShiftsForHilal);
        const selectedShiftForHilal = availableShiftsForHilal.find(item => item.range === payload);
        yield put({
          type: 'save',
          payload: {
            selectedShiftForHilal: selectedShiftForHilal ? selectedShiftForHilal : null,
          },
        });
      } catch (error) {
        console.log('setSelectedShiftByByRange error', error);
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
