import { notification } from 'antd';
import { list, create, batchCreate, update, remove, batchDelete } from '@/services/firestore';

  const namespace = 'dynamicAdjustments';
  export default {
    namespace,
  
    state: {  
      status: null,
      list: [],
      current: null
    },
  
    effects: {
      *list({ payload }, { call, put, takeEvery, take, select }) {
        // clear old before new request
        yield put({
          type: `clear:${namespace}/list`,
        });
  
        yield put({
          type: 'save',
          payload: { status: 'loading' },
        });

        // create subscription
        const service = yield call(list, {
          module: namespace,
          orderBy: ['createdAt', 'desc'],
          ...payload,
        });
        
        function* push(response) {
          let list = null
          if (response.operationType)
          {
            response.id = response['_id']
            list = yield select(state => state.dynamicAdjustments.list);
            list = JSON.parse(JSON.stringify(list))
     
            let index = list.findIndex(x => x.id == response.id)
            if (index > -1)
            {
              if (response.operationType == 'delete')
                list.splice(index, 1);
              else
              {
                delete response['operationType']
                list[index] = response
              }
            }
            else {
              delete response['operationType']
              list.push(response)
            }
          }
          else
            list = Array.isArray(response)  ? response : [];

          yield put({
            type: 'saveList',
            payload: list
          });
        }
  
        // on every callback from service
        yield takeEvery(service, push);
  
        // unsubscribe & clear when this action fired
        yield take(`clear:${namespace}/list`);
        service.close();
        yield put({
          type: 'clearList',
        });
      },
  
      *create({ payload }, { call, put }) {
        yield put({
          type: 'save',
          payload: { status: 'loading' },
        });
  
        try {
          const response = yield call(create, {
            module: namespace,
            ...payload,
          });
          if (response) {
            notification.success({
              message: 'Dynamic adjustment added',
            });

            yield put({
              type: 'save',
              payload: { current: response },
            });
          }
          yield put({
            type: 'save',
            payload: { status: 'successCreate' },
          });
        } catch (error) {
          // console.log("error", error);
          notification.error({
            message: error.message,
          });
  
          yield put({
            type: 'save',
            payload: { status: 'error' },
          });
        }
      },
      *batchCreate({ payload }, { call, put }) {
        yield put({
          type: 'save',
          payload: { loadingCreate: true },
        });
  
        try {
          const response = yield call(batchCreate, {
            module: namespace,
            ...payload,
          });
          if (response) {
            notification.success({
              message: 'Dynamic adjustments added',
            });
          }
          yield put({
            type: 'save',
            payload: { loadingCreate: false },
          });
        } catch (error) {
          // console.log("error", error);
          notification.error({
            message: error.message,
          });
  
          yield put({
            type: 'save',
            payload: { loadingCreate: false },
          });
        }
      },
      *batchDelete({ payload }, { call, put }) {
        yield put({
          type: 'save',
          payload: { loadingRemove: true },
        });
  
        try {
          const response = yield call(batchDelete, {
            module: namespace,
            ...payload,
          });
          if (response) {
            notification.success({
              message: 'Dynamic adjustments deleted',
            });
          }
          yield put({
            type: 'save',
            payload: { loadingRemove: false },
          });
        } catch (error) {
          // console.log("error", error);
          notification.error({
            message: error.message,
          });
  
          yield put({
            type: 'save',
            payload: { loadingRemove: false },
          });
        }
      },
  
      *update({ payload }, { call, put }) {
        try {
          yield put({
            type: 'save',
            payload: { status: 'loading' },
          });
          const response = yield call(update, {
            module: namespace,
            ...payload,
          });
          yield put({
            type: 'save',
            payload: { status: 'successUpdate' },
          });
        } catch (error) {
          console.error('update error', error);
          notification.error({
            message: error.message,
          });
          yield put({
            type: 'save',
            payload: { status: 'error' },
          });
        }
      },
  
      *remove({ payload }, { call, put }) {
        yield put({
          type: 'save',
          payload: { status: 'loading' },
        });
  
        try {
          const response = yield call(remove, {
            module: namespace,
            ...payload,
          });
          if (response) {
            notification.success({
              message: 'Dynamic adjustment deleted',
            });
          }
          yield put({
            type: 'save',
            payload: { status: 'successRemove' },
          });
        } catch (error) {
          // console.log("error", error);
          notification.error({
            message: error.message,
          });
  
          yield put({
            type: 'save',
            payload: { status: 'error' },
          });
        }
      },
      *unsubscribe(_, { put }) {
        yield put({ type: `clear:${namespace}/list` });
      },
    },
  
    reducers: {
      save(state, { payload }) {
        return {
          ...state,
          ...payload,
        };
      },
      saveList(state, { payload }) {
        return {
          ...state,
          list: payload,
          status: 'successList',
        };
      },
      clearList(state) {
        return {
          ...state,
          list: [],
          status: null,
        };
      },
    },
  };