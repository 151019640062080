import moment from 'moment-timezone';
import { notification } from 'antd';
import { addReason } from '@/services/firebase';
import { isPlannedowntimeLog } from '../utils/downtime';

export default {
  namespace: 'reasons',

  state: {
    loading: false,
    selectedDowntime: false,
  },

  effects: {
    *add({ payload }, { call, put }) {
      const response = yield call(addReason, payload);
      if (response) {
        if (isPlannedowntimeLog(payload)) {
          yield put({ type: 'downtimeLog/generateStatsForTime', payload });
        } else {
          notification['success']({
            message: 'Stoppage reason logged !',
            description: payload + ' @' + moment().format('h:mm:ss a'),
          });
        }
      }
    },
    *fetchLastDowntime(_, { call, put }) {
      const response = yield call(addReason, payload);
    },
  },

  reducers: {
    saveSelectedDowntime(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
