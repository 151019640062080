import { eventChannel } from 'dva/saga';
import { socket } from '../utils/firebase';
import { generateId } from '../utils/generateId';
import errorHandler from '../utils/errorHandler';
import { createLog } from './firebase'
import AuthService from '../utils/AuthProvider'
import config from '@/../config';


const moduleGenerateId = (module, params, data) => {
  return getModuleConfig(module, params).idGenerator(data);
};

const getModuleConfig = (module, params) => {
  let idGenerator = () => generateId(`${module.substr(0, 2)}`); // <== @todo: REPLACE THIS WITH UUID
  let db;
  switch (module) {
    case 'plant':
      idGenerator = ({ plantName }) => generateId('', { slug: plantName }); // should generate slug from data.name
      break;

    case 'productionLine':
      idGenerator = ({ lineName }) => generateId('', { slug: lineName }); // should generate slug from data.name
      break;

    case 'logs':
      idGenerator = ({ lineName, timestamp, userId }) => generateId('', { slug: lineName + userId + timestamp }); // should generate slug from data.name
      break;

    default:
      break;
  }

  return { db, idGenerator };
};

/**
 * Get list of documents
 */
export const list = async ({ module, params, orderBy, conditions, attributes, stream = true }) => {
  // if(module === 'stats') {
  //   console.log("module, params, orderBy, conditions, stream", module, params, orderBy, conditions, stream );
  //   return;
  // }
  // let ref = moduleGetDB(module, params);
  // where conditions [['key', '==', 'value'],...]
  // if (conditions) {
  //   conditions.map(condition => {
  //     if (condition.length < 3) {
  //       return console.error('Incorrect condition provided for firestore');
  //     }
  //     ref = ref.where(condition[0], condition[1], condition[2]);
  //   });
  // }

  params = params ? params : {}
  params.socketId = params.productionLineId ? `${params.productionLineId}-${module}` : module
  if (params.productionLineId) {
    conditions = conditions ? {
      ...conditions,
      lineId: params.productionLineId
    } :
      {
        lineId: params.productionLineId
      }
  }
  // conditions.machine = params && params.machine  ? params.machine : null
  if (params && conditions) {
    conditions.machine = params.machine ? params.machine : null
  }
  if (module == 'roles')
    params.socketId = `${conditions.plantId}-${module}`

  if (stream) {
    socket.emit('join', params.socketId);

    if (!window.rooms)
      window.rooms = [params.socketId]
    else if (!window.rooms.includes(params.socketId))
      window.rooms.push(params.socketId)
  }

  // order by
  // if (orderBy) ref = ref.orderBy(orderBy[0], orderBy[1]);
  return stream ? streamList({ params, module, socket, conditions, attributes }) : await queryList({ module, conditions, attributes });
};
/**
 * Get single document with provided id in params
 */
export const read = async ({ module, conditions, no404error = false, stream = true }) => {
  try {
    let token = await AuthService.getToken()

    let res = await fetch(`${config.functionsApi}/${module}/get?conditions=${JSON.stringify(conditions)}`, {
      method: 'get',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    })
    res = await res.json()
    return {
      id: res['_id'],
      ...res,
    };
  }
  catch (err) {
    console.log(err)
    errorHandler({
      status: 400,
      statusText: err,
      statusRaw: err,
    });
    return false;
  }
  // const ref = moduleGetDB(module, params).doc(params.id);
  // return stream
  //   ? streamRead(ref, { params, no404error })
  //   : await queryRead(ref, { params, no404error });
};
// Get list of documents
const queryList = async ({ module, conditions, attributes }) => {
  let docs = [];
  try {
    let token = await AuthService.getToken()
    let req = 'list';
    if (module == 'mergeStats')
    {
      req = 'mergelist';
    }

    let res = await fetch(`${config.functionsApi}/${module}/${req}?conditions=${JSON.stringify(conditions)}&attributes=${JSON.stringify(attributes)}`, {
      method: 'get',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
          })
    
    res = await res.json()
    res.forEach(doc => {
      docs.push({
        id: doc['_id'],
        ...doc,
      });
    });
    return docs
  } catch (err) {
    errorHandler({
      status: 400,
      statusText: err,
      statusRaw: err,
    });
    return false;
  }
};
// Get list of documents and listen for changes
const streamList = async ({ params, module, socket, conditions, attributes }) => {
  let token = await AuthService.getToken()
  return eventChannel((emitter) => {
    fetch(`${config.functionsApi}/${module}/list?conditions=${JSON.stringify(conditions)}&attributes=${JSON.stringify(attributes)}`, {
      method: 'get',
      headers: { 'Authorization': `Bearer ${token}` },
    }).then(res => {
      if (res.status >= 400 && res.status < 600) {
        throw new Error("Bad response from server");
      }
      res.json().then((data => {
        if (data) {
          data.map(x => x.id = x['_id'])
          emitter(data)
        }
      }))
    }).catch(err => {
      errorHandler({
        status: 400,
        statusText: err,
        statusRaw: err,
      });
      emitter([]);
    })
    socket.on(params.socketId, function (data) {
      if (!conditions || (data.machine == conditions.machine || data.operationType == 'delete'))
        emitter(data)
    });

    // The subscriber must return an unsubscribe function
    return () => {
      socket.emit('leave', params.socketId)
      socket.off(params.socketId)
      if (window.rooms)
        window.rooms = window.rooms.filter(x => x != params.socketId)
    }

    // unsubscribe()
  });
};
// Get a document
const queryRead = async (ref, { params }) => {
  let docs = [];
  try {
    const doc = await ref.get();
    if (doc.exists) {
      return {
        id: doc.id,
        ...doc.data(),
      };
    } else return false;
  } catch (err) {
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
    return false;
  }
};
// Get a document and listen to changes
const streamRead = async (ref, { params, no404error = false }) => {
  return eventChannel(emitter => {
    const unsubscribe = ref.onSnapshot(
      async querySnapshot => {
        let singleDoc = false;
        if (querySnapshot.exists) {
          singleDoc = {
            id: querySnapshot.id,
            ...querySnapshot.data(),
          };
        } else {
          console.log('404 read', module + ' doesnt exist with id' + params.id);
          if (!no404error) {
            errorHandler({
              status: 404,
              statusText: module + ' doesnt exist with id' + params.id,
            });
          }
        }
        emitter(singleDoc);
        singleDoc = {};
      },
      err => {
        errorHandler({
          status: err.code,
          statusText: err.message,
          statusRaw: err,
        });
        emitter(false);
      }
    );
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });
};
export const create = async ({ module, params, data }) => {
  const id = params && params.id ? params.id : moduleGenerateId(module, params, data);
  try {
    // let ref = moduleGetDB(module, params);
    const inputData = {
      createdAt: new Date(),
      ...data,
    };
    let result = ''
    // if (useFirestoreId) {
    //   const snapShot = await ref.add(inputData);
    //   result = snapShot.id;
    // } else {
    inputData['_id'] = id
    if (params) {
      if (params.productionLineId)
        inputData.lineId = params.productionLineId
      if (params.plantId)
        inputData.plantId = params.plantId
    }
    let token = await AuthService.getToken()

    let response = await fetch(`${config.functionsApi}/${module}/create`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(inputData)
    })
    // await ref.doc(id).set(inputData);
    if (response.status != 200) {
      errorHandler({
        status: response.status,
        statusText: "Access Restricted",
        statusRaw: "Access Restricted",
      });
      return false
    }

    result = id;


    if (params != null && params.log != null)
      await createLog(params.log)
    return result
  } catch (err) {
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
    return false;
  }
};

export const dynamicAdjustmentsForTreet = async (module, params, data) => {
  const id = params && params.id ? params.id : moduleGenerateId(module, params, data);
  try {
    // let ref = moduleGetDB(module, params);
    const inputData = {
      createdAt: new Date(),
      ...data,
    };
    let result = ''
    // if (useFirestoreId) {
    //   const snapShot = await ref.add(inputData);
    //   result = snapShot.id;
    // } else {
    inputData['_id'] = id
    if (params) {
      if (params.productionLineId)
        inputData.lineId = params.productionLineId
      if (params.plantId)
        inputData.plantId = params.plantId
      if (params.createdAt)
        inputData.createdAt = params.createdAt  
    }
    let token = await AuthService.getToken()

    let response = await fetch(`${config.functionsApi}/${module}/create`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(inputData)
    })
    // await ref.doc(id).set(inputData);
    if (response.status != 200) {
      errorHandler({
        status: response.status,
        statusText: "Access Restricted",
        statusRaw: "Access Restricted",
      });
      return false
    }

    result = response;


    if (params != null && params.log != null)
      await createLog(params.log)
    return result
  } catch (err) {
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
    return false;
  }
};

export const machineBatchOperation = async (data) => {
  try {
    let token = await AuthService.getToken()
    for (let doc of data.insertDocs) {
      doc['_id'] = generateId(`${data.collectionId.substr(0, 2)}`)
    }

    await fetch(`${config.functionsApi}/BulkSyncMachines`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(data)
    })
    return true
  } catch (err) {
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
    return false;
  }
};

export const syncMachineShifts = async (data) => {
  try {
    let token = await AuthService.getToken()

    await fetch(`${config.functionsApi}/SyncShifts`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(data)
    })
    return true
  } catch (err) {
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
    return false;
  }
};





export const machineBatchQuery = async (data) => {
  try {
    let token = await AuthService.getToken()

    let results = await fetch(`${config.functionsApi}/productBatchQuery`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(data)
    })
    results = await results.json()
    console.log(results)
    // let results = {}
    // for (let machineId of query.machineIds) {
    //   let ref = moduleGetDB(module, {
    //     machine: machineId,
    //     productionLineId: query.productionLineId
    //   })
    //   if (conditions) {
    //     conditions.map(condition => {
    //       if (condition.length < 3) {
    //         return console.error('Incorrect condition provided for firestore');
    //       }
    //       ref = ref.where(condition[0], condition[1], condition[2]);
    //     });
    //   }
    //   let snapshot = await ref.limit(1).get()
    //   let singleDocument = null;
    //   snapshot.forEach(doc => {
    //     if (doc.exists) {
    //       singleDocument = doc.data();
    //       singleDocument.id = doc.id
    //     }
    //   });
    //   results[machineId] = singleDocument
    // }
    return results
  } catch (err) {
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
    return false;
  }
};



export const createMachine = ({ productionLine, products, dynamicAdjustments, plannedDowntimes, machine }) => {
  return new Promise(async (resolve, reject) => {
    productionLine = {
      updatedAt: new Date(),
      '_id': productionLine.id,
      ...productionLine,
    }
    delete productionLine['id']
    products.map(record => {
      record['_id'] = generateId(`${'products'.substr(0, 2)}`)
      delete record['id']
    })
    dynamicAdjustments.map(record => {
      record['_id'] = generateId(`${'dynamicAdjustments'.substr(0, 2)}`)
      delete record['id']
    })
    plannedDowntimes.map(record => {
      record['_id'] = generateId(`${'plannedDowntimes'.substr(0, 2)}`)
      delete record['id']
    })
    let token = await AuthService.getToken()
    await fetch(`${config.functionsApi}/createmachine`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify({ productionLine, products, dynamicAdjustments, plannedDowntimes, machine })
    })

    // const snapshot = await firestore
    //   .collection('productionLine')
    //   .doc(id)
    //   .update({
    //     ...data,
    //   });
    // if (log != null) createLog(log);
    resolve(true);
  });
};
export const update = async ({ module, params, data }) => {
  try {
    // const ref = moduleGetDB(module, params);
    // await ref.doc(params.id).update({
    //   updatedAt: new Date(),
    //   ...data,
    // });
    let inputData = {
      updatedAt: new Date(),
      '_id': params.id,
      ...data,
    }
    delete inputData['id']
    let token = await AuthService.getToken()
    await fetch(`${config.functionsApi}/${module}/update`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(inputData)
    })

    if (params != null && params.log != null)
      await createLog(params.log)
    return true;
  } catch (err) {
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
    return false;
  }
};
// cant name it 'delete' as its reserved in JS
export const remove = async ({ module, params, data }) => {
  try {
    // const ref = moduleGetDB(module, params);
    let token = await AuthService.getToken()
    await fetch(`${config.functionsApi}/${module}/delete?id=${params.id}&lineId=${params.productionLineId}&plantId=${params.plantId}`, {
      method: 'delete',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    })

    // await ref.doc(params.id).delete();
    if (params != null && params.log != null)
      await createLog(params.log)
    return true;
  } catch (err) {
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
    return false;
  }
};
