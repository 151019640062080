import moment from 'moment-timezone';

/**
 * Converts time string relative to given day
 * @param {string} string Time string e.g 7:00
 * @param {Date} day JS Time object
 */
export const convertTimeString = ({ string, day }) => {
  const split = string.split(':');

  if (split.length < 2) {
    console.log('Incorrect time format', string);
    return;
  }

  return moment(day)
    .startOf('day')
    .add(split[0], 'hours')
    .add(split[1], 'minutes')
    .toDate();
};

export const currentShiftForTime = function(shifts, timeString, forTime) {
  const timeNow = timeString
    ? moment(timeString, 'M/D/YY H:mm')
        .toDate()
    : moment(forTime ? forTime : new Date()).toDate();
  return shifts.reduce((result, shift) => {
    let fromTime = convertTimeString({ string: shift.from, day: timeNow });
    let toTime = convertTimeString({ string: shift.to, day: timeNow });

    // if ending time is of after midnight
    if (moment(toTime).isBefore(fromTime)) {
      // if current time is after midght
      if (moment(fromTime).isAfter(moment(timeNow))) {
        fromTime = moment(fromTime)
          .subtract(1, 'day')
          .toDate();
      } else {
        toTime = moment(toTime)
          .add(1, 'day')
          .toDate();
      }
    }

    if (moment(fromTime).isBefore(timeNow) && moment(toTime).isAfter(timeNow)) {
      result = {
        fromTime,
        toTime,
        ...shift,
      };

      console.log(
        'current Shift',
        result.name,
        moment(result.fromTime).format('M/D/YY h:mm:ss a'),
        moment(result.toTime).format('M/D/YY h:mm:ss a')
      );
    }
    return result;
  }, false);
};

export const currentShift = function(shifts) {
  const timeNow = moment(new Date())
    .toDate();
  return shifts.reduce((result, shift) => {
    let fromTime = convertTimeString({ string: shift.from, day: timeNow });
    let toTime = convertTimeString({ string: shift.to, day: timeNow });

    // if ending time is of after midnight
    if (moment(toTime).isBefore(fromTime)) {
      // if current time is after midght
      if (moment(fromTime).isAfter(moment(timeNow))) {
        fromTime = moment(fromTime)
          .subtract(1, 'day')
          .toDate();
      } else {
        toTime = moment(toTime)
          .add(1, 'day')
          .toDate();
      }
    }

    if (moment(fromTime).isBefore(timeNow) && moment(toTime).isAfter(timeNow)) {
      result = {
        fromTime,
        toTime,
        ...shift,
      };
    }
    return result;
  }, false);
};

export const getShiftByRange = ({ range, shifts }) => {
  const filtered = shifts.filter(shift => shift.range === range);
  return filtered.length > 0 ? filtered[0] : false;
};

export const shiftToDateRelative = function({ shift, day, timezone }) {
  if (timezone)
    moment.tz.setDefault(timezone)

  if (!shift) return shift;
  const relativeTime = day
    ? (timezone ? moment(day, 'M/D/YY').tz(timezone) : moment(day, 'M/D/YY'))
        .endOf('day')
        .toDate()
    : new Date();
  let fromTime = convertTimeString({ string: shift.from, day: relativeTime });
  let toTime = convertTimeString({ string: shift.to, day: relativeTime });

  // if ending time is of after midnight
  if (moment(toTime).isBefore(fromTime)) {
    // if given time is before shift end time
    if (moment(relativeTime).isBefore(toTime)) {
      fromTime = moment(fromTime)
        .subtract(1, 'day')
        .toDate();
    } else {
      toTime = moment(toTime)
        .add(1, 'day')
        .toDate();
    }
  }

  return {
    ...shift,
    fromTime,
    toTime,
  };
};

export const stringHours2NumHours = hours => {
  const colon = hours.indexOf(':');
  if (colon < 1) return Number(hours.slice(colon + 1));

  const prefix = hours.substr(0, colon);
  const suffix = hours.slice(colon + 1);

  return Number(prefix) + Number(suffix) / 60;
};
