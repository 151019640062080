import AuthService from '../utils/AuthProvider';
const  { fetchAuthenticated } = AuthService

const namespace = 'user';
export default {
  namespace,

  state: {
    list: [],
    currentUser: {},
    loading: false,
    statusOperation: '',
  },

  effects: {
    // Dummy data
    *fetchCurrent(_, { call, put }) {
      const profile =  yield call(fetchAuthenticated)
      const response = {
        name: 'Dummy user',
        avatar: 'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png',
        userid: '00000001',
        id: 'test132',
        email: profile.email,
        signature: 'signature',
        title: 'title',
        group: 'group',
        tags: [],
        notifyCount: 12,  
        unreadCount: 0,
        country: 'Pakistan',
        geographic: {
          province: { 
            label: 'Sindh',
            key: '330000',
          },
          city: {
            label: 'Karachi',
            key: '75300',
          },
        },
        address: 'S-16, 8th Street, Phase 2, DHA',
        phone: '0752-268888888',
      };
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
    },
    // *fetchCurrent(_, { call, put, takeEvery, take }) {
    //   yield put({
    //     type: `clear:${namespace}/current`,
    //   });
    //   yield put({
    //     type: 'save',
    //     payload: {
    //       loading: false,
    //     },
    //   });

    //   console.log("fetchCurrent");

    //   // create subscription
    //   const service = yield call(currentUserInfo);

    //   function* push(response) {
    //     if (!response || Object.entries(response).length < 1) {
    //       yield put({
    //         type: 'clear',
    //       });
    //       return;
    //     }

    //     yield put({
    //       type: 'save',
    //       payload: {
    //         currentUser: {
    //           ...response,
    //         },
    //         loading: false,
    //       },
    //     });
    //   }

    //   // subscribe function to subscription created above
    //   yield takeEvery(service, push);

    //   // unsubscribe when asked by creator
    //   yield take(`clear:${namespace}/current`);
    //   service.close();
    // },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
        loading: false,
      };
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};
