import { notification } from 'antd';
import router from 'umi/router';

const codeMessage = {
  200: 'The server successfully returned the requested data. ',
  201: 'New or modified data is successful. ',
  202: 'A request has entered the background queue (asynchronous task). ',
  204: 'Delete data successfully. ',
  400: 'The request was sent with an error. The server did not perform any operations to create or modify data. ',
  401: 'The user does not have permission. ',
  403: 'User is authorized, but access is forbidden. ',
  404: 'The request was made to a record that does not exist, and the server did not operate. ',
  406: 'The format of the request is not available. ',
  410: 'The requested resource is permanently deleted and will not be obtained again. ',
  422: 'When creating an object, a validation error occurred. ',
  500: 'The server has an error, please check the server. ',
  502: 'Gateway error. ',
  503: 'The service is unavailable, the server is temporarily overloaded or maintained. ',
  504: 'The gateway timed out. ',
};

const errorHandler = (error) => {
    console.log("errorHandler", error);
    let { status, statusText } = error;
    const errortext = codeMessage[status] || statusText;

    switch (status) {
        case 'permission-denied':
            status = 403;
            break;
    }
    if (status === 401) {
      notification.error({
        message: 'Not logged in or login has expired, please log in again.',
      });
      // @HACK
      /* eslint-disable no-underscore-dangle */
      window.g_app._store.dispatch({
        type: 'login/logout',
      });
      return;
    }
    notification.error({
      message: `Request error ${status}`,
      description: errortext,
    });
    // environment should not be used
    if (status === 403) {
      router.push('/exception/403');
      return;
    }
    if (status <= 504 && status >= 500) {
      router.push('/exception/500');
      return;
    }
    if (status >= 404 && status < 422) {
      router.push('/exception/404');
    }
  };

  export default errorHandler;