export default {
  'menu.Home': 'Home',
  'menu.line-operator': 'Line Operator',
  'menu.average-dashboard': 'Plant Averages View',
  'menu.line-settings': 'Line Settings',
  'menu.plant-settings': 'Plant Settings',
  'menu.downtimeLog': 'Downtime Log',
  'menu.integrations': 'Integrations',
  'menu.maintanence-kpis': 'Maintanence Kpis',
  'menu.merge-dashboard':'Merge Dashboard',
  'menu.batch-dashboard':'Batchwise Dashboard',
  'menu.line-performance-monitor':'Line Performance Monitor',
  'menu.line-level-board':'Line Level Board',
  'menu.leaderboard': 'Line Board',
  'menu.audit-trails': 'Audit Trails',
  'menu.home': 'Home',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register.result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.form': 'Form',
  'menu.form.basicform': 'Basic Form',
  'menu.form.stepform': 'Step Form',
  'menu.form.stepform.info': 'Step Form(write transfer information)',
  'menu.form.stepform.confirm': 'Step Form(confirm transfer information)',
  'menu.form.stepform.result': 'Step Form(finished)',
  'menu.form.advancedform': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.searchtable': 'Search Table',
  'menu.list.basiclist': 'Basic List',
  'menu.list.cardlist': 'Card List',
  'menu.list.searchlist': 'Search List',
  'menu.list.searchlist.articles': 'Search List(articles)',
  'menu.list.searchlist.projects': 'Search List(projects)',
  'menu.list.searchlist.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.account.changepassword': 'Change Password',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
};
