import { notification } from 'antd';
import { list, find, create, update, remove } from '@/services/firestore';
import { setRoles, isSuperAdminByEmail} from '@/utils/authority';
import defaultRoles from '@/../config/defaultRoles';

const namespace = 'roles';
export default {
  namespace,

  state: {
    loadingList: false,
    loadingUpdate: false,
    loadingCreate: false,
    loadingRemove: false,

    list: [],
    current: null,
  },

  effects: {
    *list({ payload }, { call, put, takeEvery, take, select }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/list`,
      });

      yield put({
        type: 'save',
        payload: { loadingList: true },
      });

      // create subscription
      const service = yield call(list, {
        module: namespace,
        orderBy: ['createdAt', 'desc'],
        ...payload,
      });

      function* push(response) {
        let list = null
        if (response.operationType)
        {
          response.id = response['_id']
          list = yield select(state => state.roles.list);
          list = JSON.parse(JSON.stringify(list))
   
          let index = list.findIndex(x => x.id == response.id)
          if (index > -1)
          {
            if (response.operationType == 'delete')
              list.splice(index, 1);
            else
            {
              delete response['operationType']
              list[index] = response
            }
          }
          else {
            delete response['operationType']
            list.push(response)
          }
          yield put({
            type: 'saveList',
            payload: list,
          });
        }
        else
        {
          list = Array.isArray(response)  ? response : [];
          const userEmail = yield select(state => state.login.userEmail);
          yield put({
            type: 'saveList',
            payload: [
              ...(isSuperAdminByEmail(userEmail) ? defaultRoles : []),
              ...list
            ],
          });
        }

        setRoles(list);
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/list`);
      service.close();
      yield put({
        type: 'clearList',
      });
    },

    *create({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingCreate: true },
      });

      try {
        const response = yield call(create, {
          module: namespace,
          ...payload,
        });

        yield put({
          type: 'save',
          payload: { loadingCreate: false },
        });
        notification.success({
          message: 'Role has been created',
        });
      } catch (error) {
        // console.log("error", error);
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingCreate: false },
        });
      }
    },

    *update({ payload }, { call, put }) {
      try {
        yield put({
          type: 'save',
          payload: { loadingUpdate: true },
        });
        const response = yield call(update, {
          module: namespace,
          ...payload,
        });
        yield put({
          type: 'save',
          payload: { loadingUpdate: false },
        });
      } catch (error) {
        console.error('update error', error);
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { loadingUpdate: false },
        });
      }
    },

    *remove({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingRemove: true },
      });

      try {
        const response = yield call(remove, {
          module: namespace,
          ...payload,
        });
        if (response) {
          notification.success({
            message: 'Role deleted',
          });
        }
        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      } catch (error) {
        // console.log("error", error);
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      }
    },
    *unsubscribe(_, { put }) {
      yield put({ type: `clear:${namespace}/list` });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
        loadingList: false,
      };
    },
    clearList(state) {
      return {
        ...state,
        list: [],
        loadingList: false,
      };
    },
  },
};
