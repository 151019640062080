import moment from 'moment-timezone';
import { socket } from '../utils/firebase';
import { convertTimeString, shiftToDateRelative } from '../utils/shifts';
import auth0Client from '../pages/User/Auth';
import AuthService from '../utils/AuthProvider'
import { eventChannel } from 'dva/saga';
import { evaluate } from 'mathjs';
import { FaWindowRestore } from 'react-icons/fa';
import config from '@/../config';
import create from 'antd/lib/icon/IconFont';

// export async function currentUserInfo() {
//   const user = currentUser();
//   if (!user) return false;
//   let ref = firestore.collection('users').doc(user.email);

//   return eventChannel(emitter => {
//     const unsubscribe = ref.onSnapshot(async querySnapshot => {
//       let singleDoc = {};
//       if (querySnapshot.exists) {
//         const data = querySnapshot.data();
//         singleDoc = {
//           avatar: 'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png',
//           signature: 'signature',
//           title: 'title',
//           group: 'group',
//           tags: [],
//           country: 'Pakistan',
//           geographic: {
//             province: {
//               label: 'Sindh',
//               key: '330000',
//             },
//             city: {
//               label: 'Karachi',
//               key: '75300',
//             },
//           },

//           id: querySnapshot.id,
//           email: data.email ? data.email : user.email,
//           ...data,
//         };
//       }

//       emitter(singleDoc);
//       singleDoc = {};
//     });
//     // The subscriber must return an unsubscribe function
//     return () => unsubscribe();
//   });
// }
export async function updateBatchValues ({ productionLineId,batchNumber, data, log = null }){
  return new Promise(async (resolve, reject) => {
    let inputData = {
      updatedAt: new Date(),
      'batchNumber': batchNumber,
      'lineId':productionLineId,
      ...data,
    }
    let token = await AuthService.getToken()
    await fetch(`${config.functionsApi}/batches/update`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(inputData)
    })
    if (log)
      await createLog(log)

    // const snapshot = await firestore
    //   .collection('productionLine')
    //   .doc(id)
    //   .update({
    //     ...data,
    //   });
    // if (log != null) createLog(log);
    resolve(true);
  });
};

export async function addBatchChangeOver(productionLine,machine,batchNumber,product){
  let data = {
    batchNumber: batchNumber,
    started:new Date(),
    machine:machine?machine.machineName:null,
    lineId:productionLine.id,
    product:product,
    runs:[{started:new Date()}]
  }
  let token = await AuthService.getToken()
  fetch(`${config.functionsApi}/batchNumber/create`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    body: JSON.stringify(data)
  })
}
export async function setPrimaryKey(lineId) {
  let token = await AuthService.getToken()
  fetch(`${config.functionsApi}/setPrimaryKey?id=${lineId}`,
    {
      method: 'get',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    })
}

export async function fetchCharts(filters) {
  moment.tz.setDefault(filters.timezone)

  let range = shiftToDateRelative({
    shift: filters.shift,
    day: filters.time,
    timezone: filters.timezone
  });
  let timeEnd = range.toTime;
  let timeStart = range.fromTime;
  if (moment(timeStart).tz(filters.timezone).isAfter(moment().tz(filters.timezone))) {
    console.log('Out of scope', moment(timeStart).format('M/D/YY H:mm'));
    return false;
  }
  let socketId = `${filters.productionLineId}-stats`
  if(filters.lpm)
    socketId = `${socketId}-lpm`
  socket.emit('join', socketId);
  if (!window.rooms)
    window.rooms = [socketId]
  else
    window.rooms.push(socketId)

  let token = await AuthService.getToken()
  return eventChannel((emitter) => {
    fetch(`${config.functionsApi}/stats/get?conditions=${JSON.stringify({ _id: `${filters.shift.statsId}`, lineId: filters.productionLineId, plantId: filters.plantId })}`, {
      method: 'get',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    }).then(res => {
      if (res.status >= 400 && res.status < 600) {
        throw new Error("Bad response from server");
      }
      res.json().then((docData => {
        docData = docData[0]
        if (!docData) {
          emitter(false)
          return
        }
        docData = {
          reportId: docData['_id'],
          time: docData.time,
          headcount: docData.headcount,
          batches: docData.batches,
          updated_at: docData.updated_at ? docData.updated_at : docData.time,
          product: docData.product ? docData.product : false,
          shift: docData.shift ? docData.shift : false,
          quality: docData.quality,
          performance: docData.performance,
          availability: docData.availability,
          downtime: docData.downtime,
          plannedDowntime: docData.plannedDowntime,
          lineId: docData.lineId,
          downtimePerformance: docData.downtimePerformance ? docData.downtimePerformance : 0,
          goodOutput: docData.goodOutput,
          totalRejections:docData.totalRejections,
          productionTime: docData.productionTime ? docData.productionTime : null,
          idealOutput: docData.idealOutput,
          oee: docData.oee,
          oeeFinal: docData.oeeFinal,
          activeStats: docData.activeStats,
          downtimeLog: docData.downtimeLog ? docData.downtimeLog : [],
          disconnectLog: docData.disconnectLog || [],
          performanceDowntimeLog:docData.performanceDowntimeLog || [],
          disconnectSeconds: docData.disconnectSeconds || 0,
          status: docData.status ? docData.status : null,
          skippedShift: docData.skippedShift || false,
          manualShift: docData.manualShift || false,
          plannedProductionTime: docData.plannedProductionTime,
          currentBatch: docData.currentBatch,
          buffer:docData.buffer?docData.buffer:0,
          speedInMinute:docData.speedInMinute?docData.speedInMinute:0,
          averageSpeed:docData.averageSpeed?docData.averageSpeed.toFixed(2):0,
          totalCountOutput : docData.totalCountOutput?docData.totalCountOutput:docData.goodOutput ? docData.goodOutput : 0,
          Buffer1 : docData.Buffer1 ? docData.Buffer1 : 0,
          Buffer2 : docData.Buffer2 ? docData.Buffer2 : 0,
          Buffer3 : docData.Buffer3 ? docData.Buffer3 : 0,
          shiftWiseTarget:docData.shiftWiseTarget?docData.shiftWiseTarget.toFixed(0) : null,
          averageSpeedForShift : docData.averageSpeedForShift ? docData.averageSpeedForShift.toFixed(0) : null,

        }

        if (docData.oee)
          setDataForChart(docData, filters.timezone);
        emitter(docData)
      }))
    }).catch(err => {
      emitter(false);
    })
    socket.on(socketId, function (data) {
      let docData = data
      if (!docData) {
        emitter(false)
        return
      }
      if (docData['_id'] != filters.shift.statsId)
        return
      docData = {
        reportId: docData['_id'],
        time: docData.time,
        headcount: docData.headcount,
        batches: docData.batches,
        updated_at: docData.updated_at ? docData.updated_at : docData.time,
        product: docData.product ? docData.product : false,
        shift: docData.shift ? docData.shift : false,
        quality: docData.quality,
        performance: docData.performance,
        availability: docData.availability,
        downtime: docData.downtime,
        lineId: docData.lineId,
        plannedDowntime: docData.plannedDowntime,
        downtimePerformance: docData.downtimePerformance ? docData.downtimePerformance : 0,
        goodOutput: docData.goodOutput,
        totalRejections:docData.totalRejections,
        productionTime: docData.productionTime ? docData.productionTime : null,
        idealOutput: docData.idealOutput,
        oee: docData.oee,
        oeeFinal: docData.oeeFinal,
        activeStats: docData.activeStats,
        downtimeLog: docData.downtimeLog ? docData.downtimeLog : [],
        disconnectLog: docData.disconnectLog || [],
        disconnectSeconds: docData.disconnectSeconds || 0,
        status: docData.status ? docData.status : null,
        skippedShift: docData.skippedShift || false,
        manualShift: docData.manualShift || false,
        plannedProductionTime: docData.plannedProductionTime,
        buffer:docData.buffer?docData.buffer:0,
        speedInMinute:docData.speedInMinute?docData.speedInMinute:0,
        averageSpeed:docData.averageSpeed?docData.averageSpeed.toFixed(2):0,
        totalCountOutput : docData.totalCountOutput?docData.totalCountOutput:docData.goodOutput ? docData.goodOutput : 0,
        Buffer1 : docData.Buffer1 ? docData.Buffer1 : 0,
        Buffer2 : docData.Buffer2 ? docData.Buffer2 : 0,
        Buffer3 : docData.Buffer3 ? docData.Buffer3 : 0,
        shiftWiseTarget:docData.shiftWiseTarget?docData.shiftWiseTarget.toFixed(0) : null,
        averageSpeedForShift : docData.averageSpeedForShift ? docData.averageSpeedForShift.toFixed(0):null,

      }
      //console.log("DocData", docData);
      if (docData.oee)
        setDataForChart(docData, filters.timezone);
      emitter(docData)

    });

    return () => {
      socket.emit('leave', socketId)
      socket.off(socketId)
      if (window.rooms)
        window.rooms = window.rooms.filter(x => x != socketId)
    }
    // const unsubscribe = ref.onSnapshot(
    //   async (querySnapshot) => {
    //     let singleDoc = {};
    //     // console.log(querySnapshot)
    //     if (filters.collectionId != 'stats')
    //       querySnapshot = [querySnapshot]
    //     querySnapshot.forEach((doc) => {
    //       if (doc.exists) {
    //         const docData = doc.data();
    //         singleDoc = {
    //           reportId: doc.id,
    //           time: docData.time,
    //           updated_at: docData.updated_at ? docData.updated_at : docData.time,
    //           product: docData.product ? docData.product : false,
    //           shift: docData.shift ? docData.shift : false,
    //           quality: docData.quality,
    //           performance: docData.performance,
    //           availability: docData.availability,
    //           downtime: docData.downtime,
    //           plannedDowntime: docData.plannedDowntime,
    //           downtimePerformance: docData.downtimePerformance ? docData.downtimePerformance : 0,
    //           goodOutput: docData.goodOutput,
    //           totalRejections: docData.totalRejections,
    //           productionTime: docData.productionTime ? docData.productionTime : null,
    //           idealOutput: docData.idealOutput,
    //           oee: docData.oee,
    //           oeeFinal: docData.oeeFinal,
    //           activeStats: docData.activeStats,
    //           downtimeLog: docData.downtimeLog ? docData.downtimeLog : [],
    //           disconnectLog: docData.disconnectLog || [],
    //           disconnectSeconds: docData.disconnectSeconds || 0,
    //           status: docData.status ? docData.status : null,
    //           skippedShift: docData.skippedShift || false,
    //           manualShift: docData.manualShift || false,

    //         };
    //       }
    //     });
    //     emitter(singleDoc);
    //     singleDoc = {};
    //   },
    //   (err) => {
    //     emitter(false);
    //   }
    // );
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });

  function setDataForChart(singleDoc, timezone) {
    let modifiedData = [...singleDoc.oee];
    //console.log("Single", singleDoc)

    if (singleDoc.downtimeLog.length > 0)
      singleDoc.downtimeLog.forEach((log) => {
        // if(log.reason.includes("Batch")){
        // }
        let containsBatch = false;
        if (log.batchNumber) {
          console.log("dtlog", log)
        }
        let started = moment(log.started).valueOf(),
          ended = log.ended ? moment(log.ended).valueOf() : moment().valueOf();
        modifiedData = modifiedData.map((item) => {
          let time = moment(item.time['_seconds'] ? item.time['_seconds'] * 1000 : item.time).valueOf();
          if (started < time && time <= ended) {
            if (log.isPlanned || item.isPlannedDowntime)
              item.planDowntime = Number(item.value) == 0 ? 5 :Number(item.value)>0 && Number(item.value) <= 2 ? 2 : Number(item.value);
            else 
            {
              item.down = Number(item.value) == 0 ? 5 :Number(item.value)>0 && Number(item.value) <= 2 ? 2 : Number(item.value);
              item.isStop = log.isStop?item.down:null;
            }
            if (log.batchNumber && !containsBatch) {
              item.batchNumber = log.batchNumber
              containsBatch = true
            }
          }

          return item;
        });
      });

    singleDoc.disconnectLog.forEach((log) => {
      let started = moment(log.started).valueOf(),
        ended = log.ended ? moment(log.ended).valueOf() : moment().valueOf();
      modifiedData = modifiedData.map((item) => {
        let time = moment(item.time['_seconds'] ? item.time['_seconds'] * 1000 : item.time).valueOf();
        if (started < time && time <= ended) {
          if (log.isPlanned || item.isPlannedDowntime)
            item.planDowntime = Number(item.value) == 0 ? 5 :Number(item.value)>0 && Number(item.value) <= 2 ? 2 : Number(item.value);
          else item.disconnect = Number(item.value) == 0 ? 5 :Number(item.value)>0 && Number(item.value) <= 2 ? 2 : Number(item.value);
        }
        return item;
      });
    });
    let formatedData = modifiedData.map((item) => {
      const {
        time,
        value,
        availability,
        performance,
        quality,
        goodOutput,
        downtime,
        disconnectTime,
        productChanged,
        down,
        isStop,
        previousProduct,
        disconnect,
        planDowntime,
        totalRejections,
        batchNumber,
        speedInMinute,
        lineSpeed,
        totalCountOutput,
        idealOutputForGraph,
        averageSpeedForGraph,
      } = item;
      return {
        time: moment(time).toDate().toLocaleString("en-US", { timeZone: timezone }),
        timestamp: moment(time).toDate(),
        oee: parseFloat(value),
        a: Math.round(availability),
        p: Math.round(performance),
        q: Math.round(quality),
        downtime: Math.round(downtime),
        disconnectTime: Math.round(disconnectTime),
        productChanged,
        previousProduct,
        down: down,
        isStop:isStop,
        planDowntime,
        disconnect,
        gc: goodOutput,
        idealOutputForGraph:idealOutputForGraph?idealOutputForGraph.toFixed(0):0,
        averageSpeedForGraph:averageSpeedForGraph?averageSpeedForGraph.toFixed(0):0,
        totalRejections: totalRejections,
        batchNumber,
        speedInMinute:speedInMinute,
        lineSpeed
      };
    });
    singleDoc.formattedOEE = formatedData;
  }
}
export async function fetchBatchCharts(filters) {
  moment.tz.setDefault(filters.timezone)
  let socketId = `${filters.productionLineId}-batchStats`
  if(filters.lpm)
    socketId = `${socketId}-lpm`
  socket.emit('join', socketId);
  if (!window.rooms)
    window.rooms = [socketId]
  else
    window.rooms.push(socketId)

  let token = await AuthService.getToken()
  return eventChannel((emitter) => {
    fetch(`${config.functionsApi}/stats/getBatch?conditions=${JSON.stringify({ _id: `${filters.batch.batchStatsId}`, lineId: filters.productionLineId, plantId: filters.plantId })}`, {
      method: 'get',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    }).then(res => {
      if (res.status >= 400 && res.status < 600) {
        throw new Error("Bad response from server");
      }
      res.json().then((docData => {
        docData = docData[0]
        if (!docData) {
          emitter(false)
          return
        }
        let x = Object.keys(docData.totalRuns);
        console.log(x);
        docData = {
          reportId: docData['_id'],
          time: docData.time,
          batchDetails: docData.batchDetails,
          updated_at: docData.updated_at ? docData.updated_at : docData.time,
          product: docData.batchDetails.product ? docData.batchDetails.product : false,
          lineId: docData.lineId,
          goodOutput: docData.goodOutput,
          totalRejections:docData.totalRejections,
          totalRuns:docData.totalRuns,
          totalRework:docData.totalRework,
        }

        if (docData.oee)
          setDataForChart(docData, filters.timezone);
        emitter(docData)
      }))
    }).catch(err => {
      emitter(false);
    })
    socket.on(socketId, function (data) {
      let docData = data
      if (!docData) {
        emitter(false)
        return
      }
      if (docData['_id'] != filters.batch.batchStatsId)
        return
        docData = {
          reportId: docData['_id'],
          time: docData.time,
          batchDetails: docData.batchDetails,
          updated_at: docData.updated_at ? docData.updated_at : docData.time,
          product: docData.batchDetails.product ? docData.batchDetails.product : false,
          lineId: docData.lineId,
          goodOutput: docData.goodOutput,
          totalRejections:docData.totalRejections,
          totalRuns:docData.totalRuns,
          totalRework:docData.totalRework,
        }
        
      //console.log("DocData", docData);
      if (docData.oee)
        setDataForChart(docData, filters.timezone);
      emitter(docData)

    });

    return () => {
      socket.emit('leave', socketId)
      socket.off(socketId)
      if (window.rooms)
        window.rooms = window.rooms.filter(x => x != socketId)
    }
    // const unsubscribe = ref.onSnapshot(
    //   async (querySnapshot) => {
    //     let singleDoc = {};
    //     // console.log(querySnapshot)
    //     if (filters.collectionId != 'stats')
    //       querySnapshot = [querySnapshot]
    //     querySnapshot.forEach((doc) => {
    //       if (doc.exists) {
    //         const docData = doc.data();
    //         singleDoc = {
    //           reportId: doc.id,
    //           time: docData.time,
    //           updated_at: docData.updated_at ? docData.updated_at : docData.time,
    //           product: docData.product ? docData.product : false,
    //           shift: docData.shift ? docData.shift : false,
    //           quality: docData.quality,
    //           performance: docData.performance,
    //           availability: docData.availability,
    //           downtime: docData.downtime,
    //           plannedDowntime: docData.plannedDowntime,
    //           downtimePerformance: docData.downtimePerformance ? docData.downtimePerformance : 0,
    //           goodOutput: docData.goodOutput,
    //           totalRejections: docData.totalRejections,
    //           productionTime: docData.productionTime ? docData.productionTime : null,
    //           idealOutput: docData.idealOutput,
    //           oee: docData.oee,
    //           oeeFinal: docData.oeeFinal,
    //           activeStats: docData.activeStats,
    //           downtimeLog: docData.downtimeLog ? docData.downtimeLog : [],
    //           disconnectLog: docData.disconnectLog || [],
    //           disconnectSeconds: docData.disconnectSeconds || 0,
    //           status: docData.status ? docData.status : null,
    //           skippedShift: docData.skippedShift || false,
    //           manualShift: docData.manualShift || false,

    //         };
    //       }
    //     });
    //     emitter(singleDoc);
    //     singleDoc = {};
    //   },
    //   (err) => {
    //     emitter(false);
    //   }
    // );
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });

  function setDataForChart(singleDoc, timezone) {
    let modifiedData = [...singleDoc.oee];
    //console.log("Single", singleDoc)

    if (singleDoc.downtimeLog.length > 0)
      singleDoc.downtimeLog.forEach((log) => {
        // if(log.reason.includes("Batch")){
        // }
        let containsBatch = false;
        if (log.batchNumber) {
          console.log("dtlog", log)
        }
        let started = moment(log.started).valueOf(),
          ended = log.ended ? moment(log.ended).valueOf() : moment().valueOf();
        modifiedData = modifiedData.map((item) => {
          let time = moment(item.time['_seconds'] ? item.time['_seconds'] * 1000 : item.time).valueOf();
          if (started < time && time <= ended) {
            if (log.isPlanned || item.isPlannedDowntime)
              item.planDowntime = Number(item.value) == 0 ? 5 :Number(item.value)>0 && Number(item.value) <= 2 ? 2 : Number(item.value);
            else 
            {
              item.down = Number(item.value) == 0 ? 5 :Number(item.value)>0 && Number(item.value) <= 2 ? 2 : Number(item.value);
              item.isStop = log.isStop?item.down:null;
            }
            if (log.batchNumber && !containsBatch) {
              item.batchNumber = log.batchNumber
              containsBatch = true
            }
          }

          return item;
        });
      });

    singleDoc.disconnectLog.forEach((log) => {
      let started = moment(log.started).valueOf(),
        ended = log.ended ? moment(log.ended).valueOf() : moment().valueOf();
      modifiedData = modifiedData.map((item) => {
        let time = moment(item.time['_seconds'] ? item.time['_seconds'] * 1000 : item.time).valueOf();
        if (started < time && time <= ended) {
          if (log.isPlanned || item.isPlannedDowntime)
            item.planDowntime = Number(item.value) == 0 ? 5 :Number(item.value)>0 && Number(item.value) <= 2 ? 2 : Number(item.value);
          else item.disconnect = Number(item.value) == 0 ? 5 :Number(item.value)>0 && Number(item.value) <= 2 ? 2 : Number(item.value);
        }
        return item;
      });
    });
    let formatedData = modifiedData.map((item) => {
      const {
        time,
        value,
        availability,
        performance,
        quality,
        goodOutput,
        downtime,
        disconnectTime,
        productChanged,
        down,
        isStop,
        previousProduct,
        disconnect,
        planDowntime,
        totalRejections,
        batchNumber,
        speedInMinute,
        lineSpeed,
        totalCountOutput,
        idealOutputForGraph,
        averageSpeedForGraph,
      } = item;
      return {
        time: moment(time).toDate().toLocaleString("en-US", { timeZone: timezone }),
        timestamp: moment(time).toDate(),
        oee: parseFloat(value),
        a: Math.round(availability),
        p: Math.round(performance),
        q: Math.round(quality),
        downtime: Math.round(downtime),
        disconnectTime: Math.round(disconnectTime),
        productChanged,
        previousProduct,
        down: down,
        isStop:isStop,
        planDowntime,
        disconnect,
        gc: goodOutput,
        idealOutputForGraph:idealOutputForGraph?idealOutputForGraph.toFixed(0):0,
        averageSpeedForGraph:averageSpeedForGraph?averageSpeedForGraph.toFixed(0):0,
        totalRejections: totalRejections,
        batchNumber,
        speedInMinute:speedInMinute,
        lineSpeed
      };
    });
    singleDoc.formattedOEE = formatedData;
  }
}

export async function fetchMergeCharts(filters) {
  moment.tz.setDefault(filters.timezone)

  let range = shiftToDateRelative({
    shift: filters.shift,
    day: filters.time,
    timezone: filters.timezone
  });
  let timeEnd = range.toTime;
  let timeStart = range.fromTime;
  if (moment(timeStart).tz(filters.timezone).isAfter(moment().tz(filters.timezone))) {
    console.log('Out of scope', moment(timeStart).format('M/D/YY H:mm'));
    return false;
  }
  let socketId = `${filters.productionLineId}-stats`
  socket.emit('join', socketId);
  if (!window.rooms)
    window.rooms = [socketId]
  else
    window.rooms.push(socketId)

  let token = await AuthService.getToken()
  return eventChannel((emitter) => {
    fetch(`${config.functionsApi}/stats/getMerge?conditions=${JSON.stringify({ _id: `${filters.shift.statsId}`, lineId: filters.productionLineId, plantId: filters.plantId })}`, {
      method: 'get',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    }).then(res => {
      if (res.status >= 400 && res.status < 600) {
        throw new Error("Bad response from server");
      }
      res.json().then((docData => {
        docData = docData[0]
        if (!docData) {
          emitter(false)
          return
        }
        docData = {
          reportId: docData['_id'],
          time: docData.time,
          headcount: docData.headcount,
          batches: docData.batches,
          updated_at: docData.updated_at ? docData.updated_at : docData.time,
          product: docData.product ? docData.product : false,
          shift: docData.shift ? docData.shift : false,
          quality: docData.quality,
          performance: docData.performance,
          availability: docData.availability,
          downtime: docData.downtime,
          plannedDowntime: docData.plannedDowntime,
          lineId: docData.lineId,
          downtimePerformance: docData.downtimePerformance ? docData.downtimePerformance : 0,
          goodOutput: docData.goodOutput,
          totalRejections:docData.totalRejections,
          productionTime: docData.productionTime ? docData.productionTime : null,
          idealOutput: docData.idealOutput,
          oee: docData.oee,
          oeeFinal: docData.oeeFinal,
          activeStats: docData.activeStats,
          downtimeLog: docData.downtimeLog ? docData.downtimeLog : [],
          disconnectLog: docData.disconnectLog || [],
          disconnectSeconds: docData.disconnectSeconds || 0,
          status: docData.status ? docData.status : null,
          skippedShift: docData.skippedShift || false,
          manualShift: docData.manualShift || false,
          plannedProductionTime: docData.plannedProductionTime,
          currentBatch: docData.currentBatch,
          buffer:docData.buffer?docData.buffer:0,
          totalCountOutput : docData.totalCountOutput?docData.totalCountOutput:docData.goodOutput ? docData.goodOutput : 0,
          Buffer1 : docData.Buffer1 ? docData.Buffer1 : 0,
          Buffer2 : docData.Buffer2 ? docData.Buffer2 : 0,
          Buffer3 : docData.Buffer3 ? docData.Buffer3 : 0,
        }

        if (docData.oee)
          setDataForChart(docData, filters.timezone);
        emitter(docData)
      }))
    }).catch(err => {
      emitter(false);
    })
    socket.on(socketId, function (data) {
      let docData = data
      if (!docData) {
        emitter(false)
        return
      }
      if (docData['_id'] != filters.shift.statsId)
        return
      docData = {
        reportId: docData['_id'],
        time: docData.time,
        headcount: docData.headcount,
        batches: docData.batches,
        updated_at: docData.updated_at ? docData.updated_at : docData.time,
        product: docData.product ? docData.product : false,
        shift: docData.shift ? docData.shift : false,
        quality: docData.quality,
        performance: docData.performance,
        availability: docData.availability,
        downtime: docData.downtime,
        lineId: docData.lineId,
        plannedDowntime: docData.plannedDowntime,
        downtimePerformance: docData.downtimePerformance ? docData.downtimePerformance : 0,
        goodOutput: docData.goodOutput,
        totalRejections:docData.totalRejections,
        productionTime: docData.productionTime ? docData.productionTime : null,
        idealOutput: docData.idealOutput,
        oee: docData.oee,
        oeeFinal: docData.oeeFinal,
        activeStats: docData.activeStats,
        downtimeLog: docData.downtimeLog ? docData.downtimeLog : [],
        disconnectLog: docData.disconnectLog || [],
        disconnectSeconds: docData.disconnectSeconds || 0,
        status: docData.status ? docData.status : null,
        skippedShift: docData.skippedShift || false,
        manualShift: docData.manualShift || false,
        plannedProductionTime: docData.plannedProductionTime,
        buffer:docData.buffer?docData.buffer:0,
        totalCountOutput : docData.totalCountOutput?docData.totalCountOutput:docData.goodOutput ? docData.goodOutput : 0,
        Buffer1 : docData.Buffer1 ? docData.Buffer1 : 0,
        Buffer2 : docData.Buffer2 ? docData.Buffer2 : 0,
        Buffer3 : docData.Buffer3 ? docData.Buffer3 : 0,

      }
      //console.log("DocData", docData);
      if (docData.oee)
        setDataForChart(docData, filters.timezone);
      emitter(docData)

    });

    return () => {
      socket.emit('leave', socketId)
      socket.off(socketId)
      if (window.rooms)
        window.rooms = window.rooms.filter(x => x != socketId)
    }
    // const unsubscribe = ref.onSnapshot(
    //   async (querySnapshot) => {
    //     let singleDoc = {};
    //     // console.log(querySnapshot)
    //     if (filters.collectionId != 'stats')
    //       querySnapshot = [querySnapshot]
    //     querySnapshot.forEach((doc) => {
    //       if (doc.exists) {
    //         const docData = doc.data();
    //         singleDoc = {
    //           reportId: doc.id,
    //           time: docData.time,
    //           updated_at: docData.updated_at ? docData.updated_at : docData.time,
    //           product: docData.product ? docData.product : false,
    //           shift: docData.shift ? docData.shift : false,
    //           quality: docData.quality,
    //           performance: docData.performance,
    //           availability: docData.availability,
    //           downtime: docData.downtime,
    //           plannedDowntime: docData.plannedDowntime,
    //           downtimePerformance: docData.downtimePerformance ? docData.downtimePerformance : 0,
    //           goodOutput: docData.goodOutput,
    //           totalRejections: docData.totalRejections,
    //           productionTime: docData.productionTime ? docData.productionTime : null,
    //           idealOutput: docData.idealOutput,
    //           oee: docData.oee,
    //           oeeFinal: docData.oeeFinal,
    //           activeStats: docData.activeStats,
    //           downtimeLog: docData.downtimeLog ? docData.downtimeLog : [],
    //           disconnectLog: docData.disconnectLog || [],
    //           disconnectSeconds: docData.disconnectSeconds || 0,
    //           status: docData.status ? docData.status : null,
    //           skippedShift: docData.skippedShift || false,
    //           manualShift: docData.manualShift || false,

    //         };
    //       }
    //     });
    //     emitter(singleDoc);
    //     singleDoc = {};
    //   },
    //   (err) => {
    //     emitter(false);
    //   }
    // );
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });

  function setDataForChart(singleDoc, timezone) {
    let modifiedData = [...singleDoc.oee];
    //console.log("Single", singleDoc)

    if (singleDoc.downtimeLog.length > 0)
      singleDoc.downtimeLog.forEach((log) => {
        // if(log.reason.includes("Batch")){
        // }
        let containsBatch = false;
        if (log.batchNumber) {
          console.log("dtlog", log)
        }
        let started = moment(log.started).valueOf(),
          ended = log.ended ? moment(log.ended).valueOf() : moment().valueOf();
        modifiedData = modifiedData.map((item) => {
          let time = moment(item.time['_seconds'] ? item.time['_seconds'] * 1000 : item.time).valueOf();
          if (started < time && time <= ended) {
            if (log.isPlanned || item.isPlannedDowntime)
              item.planDowntime = Number(item.value) == 0 ? 5 :Number(item.value)>0 && Number(item.value) <= 2 ? 2 : Number(item.value);
            else item.down = Number(item.value) == 0 ? 5 :Number(item.value)>0 && Number(item.value) <= 2 ? 2 : Number(item.value);
            if (log.batchNumber && !containsBatch) {
              item.batchNumber = log.batchNumber
              containsBatch = true
            }
          }

          return item;
        });
      });

    singleDoc.disconnectLog.forEach((log) => {
      let started = moment(log.started).valueOf(),
        ended = log.ended ? moment(log.ended).valueOf() : moment().valueOf();
      modifiedData = modifiedData.map((item) => {
        let time = moment(item.time['_seconds'] ? item.time['_seconds'] * 1000 : item.time).valueOf();
        if (started < time && time <= ended) {
          if (log.isPlanned || item.isPlannedDowntime)
            item.planDowntime = Number(item.value) == 0 ? 5 :Number(item.value)>0 && Number(item.value) <= 2 ? 2 : Number(item.value);
          else item.disconnect = Number(item.value) == 0 ? 5 :Number(item.value)>0 && Number(item.value) <= 2 ? 2 : Number(item.value);
        }
        return item;
      });
    });
    let formatedData = modifiedData.map((item) => {
      const {
        time,
        value,
        availability,
        performance,
        quality,
        goodOutput,
        downtime,
        disconnectTime,
        productChanged,
        down,
        previousProduct,
        disconnect,
        planDowntime,
        totalRejections,
        batchNumber,
        lineSpeed,
        totalCountOutput,
        idealOutputForGraph,
        averageSpeedForGraph
      } = item;
      return {
        time: moment(time).toDate().toLocaleString("en-US", { timeZone: timezone }),
        timestamp: moment(time).toDate(),
        oee: parseFloat(value),
        a: Math.round(availability),
        p: Math.round(performance),
        q: Math.round(quality),
        downtime: Math.round(downtime),
        disconnectTime: Math.round(disconnectTime),
        productChanged,
        previousProduct,
        down: down,
        planDowntime,
        disconnect,
        gc: goodOutput,
        idealOutputForGraph:idealOutputForGraph?idealOutputForGraph.toFixed(0):0,
        averageSpeedForGraph:averageSpeedForGraph?averageSpeedForGraph.toFixed(0):0,
        totalRejections: totalRejections,
        batchNumber,
        lineSpeed
      };
    });
    singleDoc.formattedOEE = formatedData;
  }
}
export const fetchLogs = async (conditions) => {
  let token = await AuthService.getToken()
  let logs = await fetch(`${config.functionsApi}/logs/list?conditions=${JSON.stringify(conditions)}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
  })
  logs = await logs.json()
  return logs
}

export const sendSapNotification = async ({
  id,
  reason,
  lineId,
  equipmentId,
  plantId,
  started,
  timezone,
  collecitonId = 'downtimeLog',
}) => {
  try {
    
    let data = {
      '_id': id,
      reason,
      equipmentId,
      lineId,
      plantId,
      started,
      timezone
    }
    
    //data.started = moment(data.started).tz(timezone).toDate();

    let token = await AuthService.getToken()
    let resultSap = await fetch(`${config.functionsApi}/${collecitonId}/sendSapNotification`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(data)
    })
    //console.log(resultSap);
    return true;
  } catch (error) {
    
    console.log(error);
    
    return false;
  }
}
export const addReason = async ({
  id,
  reason,
  categories,
  history,
  lineId,
  machine,
  plantId,
  comment,
  collecitonId = 'downtimeLog',
  log,
  batchNumber
}) => {
  try {
    let data = {
      '_id': id,
      batchNumber,
      reason,
      categories,
      history,
      lineId,
      plantId,
      comment: comment || '',

    }
    if (machine)
      data.machine = machine
    let token = await AuthService.getToken()
    await fetch(`${config.functionsApi}/${collecitonId}/update`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(data)
    })
    if (log) {
      await createLog(log)
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const mergeDowntime = ({ downtimeBefore, downtimeAfter, prodId, plantId }) => {
  return new Promise(async (resolve, reject) => {
    let token = await AuthService.getToken()
    await fetch(`${config.functionsApi}/downtimeLog/mergeOrDemerge?lineId=${prodId}&plantId=${plantId}&id=${JSON.stringify([downtimeBefore.id, downtimeAfter.id])}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify({
        downtime: {
          reason: '',
          started: downtimeBefore.started,
          ended: downtimeAfter.ended,
          lineId: prodId,
          machine: (downtimeBefore.machine == downtimeAfter.machine) ? downtimeBefore.machine : null
        }
      })
    })
    resolve(true);
  });
};
export const demergeDowntime = ({ downtimeBefore, downtimeAfter, downtime, prodId, plantId }) => {
  return new Promise(async (resolve, reject) => {
    let token = await AuthService.getToken()

    await fetch(`${config.functionsApi}/downtimeLog/mergeOrDemerge?lineId=${prodId}&plantId=${plantId}&id=${JSON.stringify([downtime.id])}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify({
        downtimeBefore: { ...downtimeBefore, lineId: prodId },
        downtimeAfter: { ...downtimeAfter, lineId: prodId }
      })
    })
    resolve(true);
  });
};
async function getSensorsData({ lineId, machine }, plantId, startTime, endTime) {
  try {
    let token = await AuthService.getToken()
    let sensorsData = await fetch(`${config.functionsApi}/getSensorsData`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify({
        startTime,
        endTime,
        lineId,
        machine,
        plantId
      })
    })
    sensorsData = await sensorsData.json()
    return sensorsData
  } catch (error) {
    console.log(error)
  }
}

function adjustSensorsData(sensorsData) {
  let lastEntry = null;
  let adjustments = {};
  let packet = {}
  return sensorsData.reduce((total, item) => {
    let keys = Object.keys(item).filter(key => (key.includes('sensor') && !key.includes('status')) || key.includes('sr'))
    // console.log(keys)
    const adjustedSensors = keys.reduce((acc, key) => {
      // create entry in adjustments for this sensor
      if (typeof adjustments[key] === 'undefined') adjustments[key] = { add: 0, minus: 0 };

      // check for case: shift start carried forward
      // if first entry and value above 100
      // @todo: replace 100 with expected count.. maybe?
      if (!lastEntry && item[key] > 100) {
        adjustments[key].minus += item[key]; // subtraction entry for this sensor
      }

      // check for case: mid day reset
      // if new entry is less than previous
      if (packet[key]) {
        if (item[key] < packet[key] && item[key] < 1000) {
          adjustments[key].add += packet[key]; // additions entry for this sensor
        }
        packet[key] = null
      }
      else if (lastEntry && item[key] < lastEntry[key] && item[key] < 1000) {
        packet[key] = lastEntry[key]
        // if (!packet) {
        //   adjustments[key].add += lastEntry[key]; // additions entry for this sensor
        // }
        // else {
        //   packet = lastEntry
        // }
      }

      return {
        ...acc,
        [key]:
          // original value
          item[key] +
          // apply behavioural adjustments
          (adjustments[key] && adjustments[key].add ? adjustments[key].add : 0) -
          (adjustments[key] && adjustments[key].minus ? adjustments[key].minus : 0),
      };
    }, {});

    lastEntry = item;

    return total.concat({ ...item, ...adjustedSensors });
  }, []);
}
export async function fetchAndAdjustLastPacket(sensorsQuery, plantId, startTime, endTime, timezone) {
  let sensorsData = await getSensorsData(sensorsQuery, plantId, moment(startTime).tz(timezone).toDate(), moment(endTime).tz(timezone).toDate())
  let adjustedData = adjustSensorsData(sensorsData)

  return adjustedData && adjustedData.length > 0 ? adjustedData[adjustedData.length - 1] : null
}

export async function sendResetPasswordEmail(email) {
    await AuthService.fetchForgotPassword(email)
}

export async function confirmPasswordAndCode(email, code, password) {
  await AuthService.fetchConfirmPassword(email, code, password)
}
export async function alreadyAddedStopages({reason,started,productionLineId,machine}){
  let token = await AuthService.getToken()
    const res = await fetch(`${config.functionsApi}/downtimeLogDuplication/get?conditions=${JSON.stringify({ reason, started, lineId:productionLineId,machine })}`,
      {
        method: 'get',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });/*.then(res => {
        if (res.status >= 400 && res.status < 600) {
          return false;
          //throw new Error("Bad response from server");
        }
        console.log(res);
        res.json().then((docData => {
          docData = docData[0]
          if (!docData) {
            //emitter(false)
            return false
          }
          return true;
        }))
      }).catch(err => {
        return false;
        //emitter(false);
      })
      console.log(res);
      return Promise.resolve(res);*/
      return res.json();
      //console.log(productSnapshot);
      //return productSnapshot;
//console.log(productSnapshot);
//if(productSnapshot.length>0)
//return true
//return false;
}

export async function fetchSensorsData(sensorsQuery, plantId, startTime, endTime, formulae, activeProduct, timezone, productSpeedUnit) {
  const escapeRegExp = (string) => {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
  }
  let singleDoc = {};
  try {
    let sensorsData = await getSensorsData(sensorsQuery, plantId, moment(startTime).tz(timezone).toDate(), moment(endTime).tz(timezone).toDate())
    let entries = adjustSensorsData(sensorsData)
    let entriesLength = entries.length;
    let lastEntry = entries[entriesLength - 1];
    let firstEntryInMin;
    let firstEntryInHour;
    let unit = productSpeedUnit ? productSpeedUnit : 'Prod/min'
    let limit = unit == 'Prod/min' ? 1 : 60
    for (let i = entriesLength - 1; i > 0; i--) {
      firstEntryInMin = entries[i];
      let diffInMin = moment.duration(moment(lastEntry.time).diff(moment(firstEntryInMin.time))).asMinutes()
      if (diffInMin >= 1) {
        //console.log("firstEntryInMin", entries[i], lastEntry)
        break;
      }
    }
    for (let i = entriesLength - 1; i > 0; i--) {
      firstEntryInHour = entries[i];
      let diffInHour = moment.duration(moment(lastEntry.time).diff(moment(firstEntryInHour.time))).asMinutes()
      if (diffInHour >= 60) {
        break;
      }
    }

    // if (entriesLength > 6) {
    if (!formulae) {
      if (
        firstEntryInMin.sensor1 != null &&
        lastEntry.sensor1 != null
      ) {
        //console.log("no Formula", lastEntry.sensor1, firstEntryInHour.sensor1, firstEntryInMin.sensor1, activeProduct.secondary)
        let sensor1value1InMin = firstEntryInMin.sensor1 / activeProduct.secondary;
        let sensor1value1InHour = firstEntryInHour.sensor1 / activeProduct.secondary;
        let sensor1value2 = lastEntry.sensor1 / activeProduct.secondary;
        let t1 = lastEntry.time
        let t2 = firstEntryInMin.time
        let t3 = firstEntryInHour.time
        let durationInMin = moment.duration(moment(t1).diff(t2)).asSeconds()
        let durationInHour = moment.duration(moment(t1).diff(t3)).asMinutes();
        singleDoc.speedInMin = sensor1value2 - sensor1value1InMin;
        singleDoc.speedInMin = Math.round(singleDoc.speedInMin * (60 / durationInMin))
        
        singleDoc.speedInHour = Math.round(sensor1value2 - sensor1value1InHour);
      }
      else {
        singleDoc.speedInMin = 0;
        singleDoc.speedInHour = 0;

      }
    }
    else {
      if (formulae.sensorKeys) {
        let goodOutputString1InMin = formulae.goodOutput.string
        let goodOutputString1InHour = formulae.goodOutput.string
        let goodOutputString2 = formulae.goodOutput.string

        firstEntryInMin.primary = activeProduct.primary
        firstEntryInHour.primary = activeProduct.primary

        firstEntryInMin.secondary = activeProduct.secondary
        firstEntryInHour.secondary = activeProduct.secondary

        lastEntry.primary = activeProduct.primary
        lastEntry.secondary = activeProduct.secondary

        formulae.sensorKeys.map((key) => {
          goodOutputString1InMin = replaceAll(goodOutputString1InMin, key, firstEntryInMin[key] ? firstEntryInMin[key] : 0)
          goodOutputString1InHour = replaceAll(goodOutputString1InHour, key, firstEntryInHour[key] ? firstEntryInHour[key] : 0)
          goodOutputString2 = replaceAll(goodOutputString2, key, lastEntry[key] ? lastEntry[key] : 0)
        })
        let t1 = lastEntry.time
        let t2 = firstEntryInMin.time
        let t3 = firstEntryInHour.time

        //console.log("t1", t1, "t2", t2, "t3", t3);

        let sensor1value1InMin = evaluate(goodOutputString1InMin);
        let sensor1value1InHour = evaluate(goodOutputString1InHour);
        let sensor1value2 = evaluate(goodOutputString2);

        let durationInMin = moment.duration(moment(t1).diff(t2)).asSeconds()
        let durationInHour = moment.duration(moment(t1).diff(t3)).asMinutes()

        singleDoc.speedInMin = sensor1value2 - sensor1value1InMin;

        singleDoc.speedInMin = Math.round(singleDoc.speedInMin * (60 / durationInMin))

        singleDoc.speedInHour = sensor1value2 - sensor1value1InHour;
        singleDoc.speedInHour = Math.round(singleDoc.speedInHour * (60 / durationInHour))
      }
      else {
        singleDoc.speedInMin = 0;
        singleDoc.speedInHour = 0;
      }
    }
    // }
  }
  catch (err) {

    singleDoc.speedInMin = 0;
    singleDoc.speedInHour = 0;
  }
  return singleDoc;
}




export const createLog = async (data) => {

  let token = await AuthService.getToken()
  fetch(`${config.functionsApi}/${data.logData && Array.isArray(data.logData) ? 'BulkCreateLogs' : 'logs/create'}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    body: JSON.stringify(data)
  })

};

export const updateProductionLine = ({ id, data, log = null }) => {
  return new Promise(async (resolve, reject) => {
    let inputData = {
      updatedAt: new Date(),
      '_id': id,
      ...data,
    }
    delete inputData['id']
    let token = await AuthService.getToken()
    await fetch(`${config.functionsApi}/productionline/update`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(inputData)
    })
    if (log)
      await createLog(log)

    // const snapshot = await firestore
    //   .collection('productionLine')
    //   .doc(id)
    //   .update({
    //     ...data,
    //   });
    // if (log != null) createLog(log);
    resolve(true);
  });
};

export const getProductionLineById = ({ id }) => {
  return new Promise(async (resolve, reject) => {
    const snapshot = await firestore.collection('productionLine').doc(id).data();

    resolve(snapshot);
  });
};

export const getProductById = async function (lineId, productId, plantId) {
  try {
    let token = await AuthService.getToken()
    const productSnapshot = await fetch(`${config.functionsApi}/products/get?conditions=${JSON.stringify({ productId, lineId, plantId })}`,
      {
        method: 'get',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      })

    if (!productSnapshot) {
      throw new Error('Product not found.');
    }

    return {
      id: productSnapshot['_id'],
      ...productSnapshot,
    };
  } catch (error) {
    console.log('getProductById error', error);
    return false;
  }
};


const getAverages = (arr) => {
  let averages = {
    availability: 0,
    oeeFinal: 0,
    quality: 0,
    goodOutput: 0,
    totalRejections: 0,
    downtime: 0,
    downtimePerformance: 0,
    productionTime: 0,
    performance: 0
  }
  arr.forEach(obj => {
    let {
      availability,
      oeeFinal,
      quality,
      goodOutput,
      totalRejections,
      downtime,
      downtimePerformance,
      productionTime,
      performance
    } = obj
    averages.availability += availability
    averages.oeeFinal += oeeFinal
    averages.quality += quality
    averages.goodOutput += goodOutput
    averages.totalRejections += totalRejections
    averages.downtime += downtime
    averages.downtimePerformance += downtimePerformance
    averages.performance += performance
    averages.productionTime += productionTime
  })
  averages.availability = (averages.availability / arr.length).toFixed(2)
  averages.oeeFinal = (averages.oeeFinal / arr.length).toFixed(2)
  averages.quality = (averages.quality / arr.length).toFixed(2)
  averages.goodOutput = (averages.goodOutput).toFixed(0)
  averages.totalRejections = (averages.totalRejections).toFixed(0)
  averages.downtime = (averages.downtime).toFixed(2)
  averages.downtimePerformance = (averages.downtimePerformance).toFixed(2)
  averages.performance = (averages.performance / arr.length).toFixed(2)
  averages.productionTime = (averages.productionTime).toFixed(2)

  return averages
}


export const getAggregateStats = async ({ plantId, shifts, startDate, endDate, mode, lineIds }) => {
  try {
    let token = await AuthService.getToken()
    let stats = await fetch(`${config.functionsApi}/aggregateStats`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify({
        plantId, shifts, startDate, endDate, mode, lineIds
      })
    })
    let statsInText = await stats.text();
    // stats = await stats.json()
    let statsData = JSON.parse(statsInText)
    let result = {}
    result.stats = statsData
    result.statsInText = statsInText
    result.averages = statsData.length > 0 ? getAverages(statsData) : null
    return result;
  } catch (error) {
    console.log(error)
    return false;
  }
}


export const generateStatsBatchWise = async ({productionLineId,batchNumber})=>{
  let token = await AuthService.getToken()
  let stats = await fetch(`${config.functionsApi}/generateBatchwiseStatsForTime`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    body: JSON.stringify({
      productionLineId,
      batchNumber
    })
  })
}

export const generateStatsForTime = async ({ productionLineId, time, machine,statsGenerate=false,newShiftTime=null }) => {
  try {
    //console.log({ productionLineId, time });
    let token = await AuthService.getToken()
    let stats = await fetch(`${config.functionsApi}/generateStatsForTime`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify({
        productionLineId,
        time: time.toISOString(),
        machine,
        statsGenerate,
        newShiftTime
      })
    })
    stats = await stats.json()

    return stats;
  } catch (error) {
    console.log(error)
    return false;
  }
}

export const getStats = async (filters) => {
  //console.log("filters", filters);
  let token = await AuthService.getToken()
  let statsData = await fetch(`${config.functionsApi}/stats/get?conditions=${JSON.stringify({ _id: `${filters.statsId}`, lineId: filters.lineId, plantId: filters.plantId })}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
  })
  try {
    if (statsData.status >= 400 && statsData.status < 600) {
      throw new Error("Bad response from server");
    }
    else{
      statsData = await statsData.json()
      let docData = statsData[0]
      if (!docData) {
        throw new Error ("No stats record found ")
      }
      docData = {
        reportId: docData['_id'],
        product: docData.product ? docData.product : null,
        downtime: docData.downtime/60,
        plannedDowntime: docData.plannedDowntime/60,
        lineId: docData.lineId,
        goodCount: docData.goodOutput,
        rejections: docData.totalRejections,
        productionTime: docData.productionTime ? docData.productionTime/60 : null,
        buffer:docData.buffer?docData.buffer:0,
        Buffer1 : docData.Buffer1 ? docData.Buffer1 : 0,
        Buffer2 : docData.Buffer2 ? docData.Buffer2 : 0,
        Buffer3 : docData.Buffer3 ? docData.Buffer3 : 0,
        shiftWiseTarget:docData.shiftWiseTarget?docData.shiftWiseTarget.toFixed(0) : null,
        status:docData.status?docData.status:null,
      }
      //console.log('Doc Data', docData);
      return docData
    }
  } catch (error) {
    console.log(error)
    return false;
  }
}


export const getMaintainenceReport = async (data) => {
  //console.log("MTTR Data", data);
  let token = await AuthService.getToken()
  let getStats = await fetch(`${config.functionsApi}/maintenancekpis/getData?conditions=${JSON.stringify({lineId: data.lineId, mode: data.mode , startDate: data.startDate , endDate:data.endDate,machine: data.machine })}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
  })
  try {
    getStats = await getStats.json()
    //console.log(getStats)
    if (getStats.status >= 400 && getStats.status < 600) {
      console.log("throw error")
      throw new Error("Bad response from server");
    }
    else{
      if (!getStats) {
        throw new Error ("No stats record found ")
      }
      return getStats
    }
  } catch (error) {
    console.log(error)
  }
}