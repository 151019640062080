import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from 'C:/projects/procheck-web/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
          LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        path: '/user/callback',
        name: 'callback',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ 'C:/projects/procheck-web/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Callback" */ '../User/Callback'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Callback').default,
        exact: true,
      },
      {
        path: '/user',
        redirect: '/user/login',
        exact: true,
      },
      {
        path: '/user/login',
        name: 'login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ 'C:/projects/procheck-web/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Login" */ '../User/Login'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Login').default,
        exact: true,
      },
      {
        path: '/user/register',
        name: 'register',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ 'C:/projects/procheck-web/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Register" */ '../User/Register'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Register').default,
        exact: true,
      },
      {
        path: '/user/register-result',
        name: 'register.result',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ 'C:/projects/procheck-web/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__RegisterResult" */ '../User/RegisterResult'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../User/RegisterResult').default,
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
          LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    Routes: [require('../Authorized').default],
    routes: [
      {
        path: '/',
        redirect: '/home',
        exact: true,
      },
      {
        path: '/home',
        name: 'home',
        icon: 'dashboard',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Home__Index" */ '../Home/Index.js'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../Home/Index.js').default,
        authority: ['view-home', 'view-all'],
        hideInMenu: true,
        exact: true,
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        icon: 'dashboard',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Dashboard__models__activities.js' */ 'C:/projects/procheck-web/src/pages/Dashboard/models/activities.js').then(
                  m => {
                    return { namespace: 'activities', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Dashboard__models__chart.js' */ 'C:/projects/procheck-web/src/pages/Dashboard/models/chart.js').then(
                  m => {
                    return { namespace: 'chart', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Dashboard__models__dataExport.js' */ 'C:/projects/procheck-web/src/pages/Dashboard/models/dataExport.js').then(
                  m => {
                    return { namespace: 'dataExport', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Dashboard__models__monitor.js' */ 'C:/projects/procheck-web/src/pages/Dashboard/models/monitor.js').then(
                  m => {
                    return { namespace: 'monitor', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Dashboard__Index" */ '../Dashboard/Index.js'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../Dashboard/Index.js').default,
        authority: ['view-dashboard', 'view-all'],
        exact: true,
      },
      {
        path: '/avgdashboard',
        name: 'average-dashboard',
        icon: 'dashboard',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__AverageDashboard__models__activities.js' */ 'C:/projects/procheck-web/src/pages/AverageDashboard/models/activities.js').then(
                  m => {
                    return { namespace: 'activities', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__AverageDashboard__models__chart.js' */ 'C:/projects/procheck-web/src/pages/AverageDashboard/models/chart.js').then(
                  m => {
                    return { namespace: 'chart', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__AverageDashboard__models__dataExport.js' */ 'C:/projects/procheck-web/src/pages/AverageDashboard/models/dataExport.js').then(
                  m => {
                    return { namespace: 'dataExport', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__AverageDashboard__models__monitor.js' */ 'C:/projects/procheck-web/src/pages/AverageDashboard/models/monitor.js').then(
                  m => {
                    return { namespace: 'monitor', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__AverageDashboard__Index" */ '../AverageDashboard/Index.js'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../AverageDashboard/Index.js').default,
        authority: ['crfs-enabled'],
        exact: true,
      },
      {
        path: '/LinePerformanceMonitor',
        name: 'line-performance-monitor',
        icon: 'area-chart',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__LinePerformanceMonitor__models__activities.js' */ 'C:/projects/procheck-web/src/pages/LinePerformanceMonitor/models/activities.js').then(
                  m => {
                    return { namespace: 'activities', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__LinePerformanceMonitor__models__chart.js' */ 'C:/projects/procheck-web/src/pages/LinePerformanceMonitor/models/chart.js').then(
                  m => {
                    return { namespace: 'chart', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__LinePerformanceMonitor__models__dataExport.js' */ 'C:/projects/procheck-web/src/pages/LinePerformanceMonitor/models/dataExport.js').then(
                  m => {
                    return { namespace: 'dataExport', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__LinePerformanceMonitor__models__monitor.js' */ 'C:/projects/procheck-web/src/pages/LinePerformanceMonitor/models/monitor.js').then(
                  m => {
                    return { namespace: 'monitor', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__LinePerformanceMonitor__index" */ '../LinePerformanceMonitor/index.js'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../LinePerformanceMonitor/index.js').default,
        authority: ['line-performance-monitor'],
        exact: true,
      },
      {
        path: '/LineLevelBoard',
        name: 'line-level-board',
        icon: 'fund',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__LineLevelBoard__models__activities.js' */ 'C:/projects/procheck-web/src/pages/LineLevelBoard/models/activities.js').then(
                  m => {
                    return { namespace: 'activities', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__LineLevelBoard__models__chart.js' */ 'C:/projects/procheck-web/src/pages/LineLevelBoard/models/chart.js').then(
                  m => {
                    return { namespace: 'chart', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__LineLevelBoard__models__dataExport.js' */ 'C:/projects/procheck-web/src/pages/LineLevelBoard/models/dataExport.js').then(
                  m => {
                    return { namespace: 'dataExport', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__LineLevelBoard__models__monitor.js' */ 'C:/projects/procheck-web/src/pages/LineLevelBoard/models/monitor.js').then(
                  m => {
                    return { namespace: 'monitor', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__LineLevelBoard__index" */ '../LineLevelBoard/index.js'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../LineLevelBoard/index.js').default,
        authority: ['line-level-board'],
        exact: true,
      },
      {
        path: '/lineboard',
        name: 'leaderboard',
        icon: 'line-chart',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Leaderboard__Index" */ '../Leaderboard/Index.js'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../Leaderboard/Index.js').default,
        authority: ['view-leaderboard'],
        exact: true,
      },
      {
        path: '/BatchwiseDashboard',
        name: 'batch-dashboard',
        icon: 'dashboard',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__BatchDashboard__models__batchChart.js' */ 'C:/projects/procheck-web/src/pages/BatchDashboard/models/batchChart.js').then(
                  m => {
                    return { namespace: 'batchChart', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__BatchDashboard__models__batches.js' */ 'C:/projects/procheck-web/src/pages/BatchDashboard/models/batches.js').then(
                  m => {
                    return { namespace: 'batches', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__BatchDashboard__index" */ '../BatchDashboard/index.js'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../BatchDashboard/index.js').default,
        authority: ['view-batchwise-page'],
        exact: true,
      },
      {
        path: '/maintenancekpi',
        name: 'maintanence-kpis',
        icon: 'dashboard',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__PmaintanenceKpis__index" */ '../PmaintanenceKpis/index.js'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../PmaintanenceKpis/index.js').default,
        authority: ['crfs-hilal-maintenance'],
        exact: true,
      },
      {
        path: '/MergeDashboard',
        name: 'merge-dashboard',
        icon: 'dashboard',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__MergeDashboard__models__graph.js' */ 'C:/projects/procheck-web/src/pages/MergeDashboard/models/graph.js').then(
                  m => {
                    return { namespace: 'graph', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__MergeDashboard__index" */ '../MergeDashboard/index.js'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../MergeDashboard/index.js').default,
        authority: ['crfs-hilal-merged'],
        exact: true,
      },
      {
        path: '/observer',
        name: 'line-operator',
        icon: 'exclamation-circle',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Observer__models__batchesForOperator.js' */ 'C:/projects/procheck-web/src/pages/Observer/models/batchesForOperator.js').then(
                  m => {
                    return { namespace: 'batchesForOperator', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Observer__Index" */ '../Observer/Index.js'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../Observer/Index.js').default,
        authority: ['view-observer', 'view-all'],
        exact: true,
      },
      {
        path: '/downtimelog',
        name: 'downtimeLog',
        icon: 'clock-circle',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__DowntimeLog__Index" */ '../DowntimeLog/Index.js'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../DowntimeLog/Index.js').default,
        authority: ['view-downtimeLog', 'view-all'],
        exact: true,
      },
      {
        path: '/line-settings',
        name: 'line-settings',
        icon: 'control',
        authority: ['view-line-settings', 'view-all'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__LineSettings__Index" */ '../LineSettings/Index'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../LineSettings/Index').default,
        routes: [
          {
            path: '/line-settings',
            redirect: '/line-settings/Line',
            exact: true,
          },
          {
            path: '/line-settings/line',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__LineSettings__Line" */ '../LineSettings/Line'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../LineSettings/Line').default,
            exact: true,
          },
          {
            path: '/line-settings/shifts',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__LineSettings__Shifts" */ '../LineSettings/Shifts'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../LineSettings/Shifts').default,
            exact: true,
          },
          {
            path: '/line-settings/planneddowntimes',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__LineSettings__PlannedDowntimes" */ '../LineSettings/PlannedDowntimes'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../LineSettings/PlannedDowntimes').default,
            exact: true,
          },
          {
            path: '/line-settings/products',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__LineSettings__Products" */ '../LineSettings/Products'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../LineSettings/Products').default,
            exact: true,
          },
          {
            path: '/line-settings/weeklySchedule',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__LineSettings__WeeklySchedule" */ '../LineSettings/WeeklySchedule'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../LineSettings/WeeklySchedule').default,
            exact: true,
          },
          {
            path: '/line-settings/stopTime',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__LineSettings__StopTime" */ '../LineSettings/StopTime'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../LineSettings/StopTime').default,
            exact: true,
          },
          {
            path: '/line-settings/formulaCreation',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__LineSettings__FormulaCreation" */ '../LineSettings/FormulaCreation'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../LineSettings/FormulaCreation').default,
            exact: true,
          },
          {
            path: '/line-settings/manualEntry',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__LineSettings__ManualEntry" */ '../LineSettings/ManualEntry'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../LineSettings/ManualEntry').default,
            exact: true,
          },
          {
            path: '/line-settings/dailyTargets',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__LineSettings__DailyTargets" */ '../LineSettings/DailyTargets'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../LineSettings/DailyTargets').default,
            exact: true,
          },
          {
            path: '/line-settings/weeklyTargets',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__LineSettings__WeeklyTargets" */ '../LineSettings/WeeklyTargets'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../LineSettings/WeeklyTargets').default,
            exact: true,
          },
          {
            path: '/line-settings/forceStop',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__LineSettings__forceStop" */ '../LineSettings/forceStop'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../LineSettings/forceStop').default,
            exact: true,
          },
          {
            path: '/line-settings/headCount',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__LineSettings__HeadCount" */ '../LineSettings/HeadCount'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../LineSettings/HeadCount').default,
            exact: true,
          },
        ],
      },
      {
        path: '/plant-settings',
        name: 'plant-settings',
        icon: 'setting',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__PlantSettings__models__dataExport.js' */ 'C:/projects/procheck-web/src/pages/PlantSettings/models/dataExport.js').then(
                  m => {
                    return { namespace: 'dataExport', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__PlantSettings__Index" */ '../PlantSettings/Index'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../PlantSettings/Index').default,
        authority: ['view-plant-settings', 'view-all'],
        routes: [
          {
            path: '/plant-settings',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__PlantSettings__models__dataExport.js' */ 'C:/projects/procheck-web/src/pages/PlantSettings/models/dataExport.js').then(
                      m => {
                        return { namespace: 'dataExport', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__PlantSettings__Users" */ '../PlantSettings/Users'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../PlantSettings/Users').default,
            exact: true,
          },
          {
            path: '/plant-settings/users',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__PlantSettings__models__dataExport.js' */ 'C:/projects/procheck-web/src/pages/PlantSettings/models/dataExport.js').then(
                      m => {
                        return { namespace: 'dataExport', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__PlantSettings__Users" */ '../PlantSettings/Users'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../PlantSettings/Users').default,
            exact: true,
          },
          {
            path: '/plant-settings/roles',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__PlantSettings__models__dataExport.js' */ 'C:/projects/procheck-web/src/pages/PlantSettings/models/dataExport.js').then(
                      m => {
                        return { namespace: 'dataExport', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__PlantSettings__Roles" */ '../PlantSettings/Roles'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../PlantSettings/Roles').default,
            exact: true,
          },
          {
            path: '/plant-settings/alerts',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__PlantSettings__models__dataExport.js' */ 'C:/projects/procheck-web/src/pages/PlantSettings/models/dataExport.js').then(
                      m => {
                        return { namespace: 'dataExport', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__PlantSettings__Alerts" */ '../PlantSettings/Alerts'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../PlantSettings/Alerts').default,
            exact: true,
          },
          {
            path: '/plant-settings/siteChampion',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__PlantSettings__models__dataExport.js' */ 'C:/projects/procheck-web/src/pages/PlantSettings/models/dataExport.js').then(
                      m => {
                        return { namespace: 'dataExport', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__PlantSettings__SiteChampion" */ '../PlantSettings/SiteChampion'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../PlantSettings/SiteChampion').default,
            exact: true,
          },
        ],
      },
      {
        path: '/integrations',
        name: 'integrations',
        icon: 'api',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Integrations__models__integrations.js' */ 'C:/projects/procheck-web/src/pages/Integrations/models/integrations.js').then(
                  m => {
                    return { namespace: 'integrations', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Integrations__Index" */ '../Integrations/Index.js'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../Integrations/Index.js').default,
        authority: ['view-integration-details'],
        exact: true,
      },
      {
        path: '/logs',
        name: 'audit-trails',
        icon: 'history',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Logs__Index" */ '../Logs/Index.js'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../Logs/Index.js').default,
        authority: ['audit-trail-access'],
        exact: true,
      },
      {
        name: 'exception',
        icon: 'warning',
        path: '/exception',
        hideInMenu: true,
        routes: [
          {
            path: '/exception/403',
            name: 'not-permission',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ 'C:/projects/procheck-web/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__403" */ '../Exception/403'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/403').default,
            exact: true,
          },
          {
            path: '/exception/404',
            name: 'not-find',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ 'C:/projects/procheck-web/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__404" */ '../Exception/404'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/404').default,
            exact: true,
          },
          {
            path: '/exception/500',
            name: 'server-error',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ 'C:/projects/procheck-web/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__500" */ '../Exception/500'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/500').default,
            exact: true,
          },
          {
            path: '/exception/trigger',
            name: 'trigger',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ 'C:/projects/procheck-web/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__TriggerException" */ '../Exception/TriggerException'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/TriggerException').default,
            exact: true,
          },
        ],
      },
      {
        name: 'account',
        icon: 'user',
        path: '/account',
        hideInMenu: true,
        routes: [
          {
            path: '/account/settings',
            name: 'settings',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ 'C:/projects/procheck-web/src/pages/Account/Settings/models/geographic.js').then(
                      m => {
                        return { namespace: 'geographic', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Account__Settings__Info" */ '../Account/Settings/Info'),
                  LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Account/Settings/Info').default,
            routes: [
              {
                path: '/account/settings',
                redirect: '/account/settings/base',
                exact: true,
              },
              {
                path: '/account/settings/base',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ 'C:/projects/procheck-web/src/pages/Account/Settings/models/geographic.js').then(
                          m => {
                            return { namespace: 'geographic', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__Account__Settings__Info" */ '../Account/Settings/BaseView'),
                      LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/BaseView').default,
                exact: true,
              },
              {
                path: '/account/settings/security',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ 'C:/projects/procheck-web/src/pages/Account/Settings/models/geographic.js').then(
                          m => {
                            return { namespace: 'geographic', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__Account__Settings__Info" */ '../Account/Settings/SecurityView'),
                      LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/SecurityView').default,
                exact: true,
              },
              {
                path: '/account/settings/binding',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ 'C:/projects/procheck-web/src/pages/Account/Settings/models/geographic.js').then(
                          m => {
                            return { namespace: 'geographic', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__Account__Settings__Info" */ '../Account/Settings/BindingView'),
                      LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/BindingView').default,
                exact: true,
              },
              {
                path: '/account/settings/notification',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ 'C:/projects/procheck-web/src/pages/Account/Settings/models/geographic.js').then(
                          m => {
                            return { namespace: 'geographic', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__Account__Settings__Info" */ '../Account/Settings/NotificationView'),
                      LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/NotificationView').default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('C:/projects/procheck-web/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
