import { notification } from 'antd';
import { list, create, update, remove, batchCreate, batchDelete } from '@/services/firestore';
import { generateStatsForTime } from '../services/firebase'
const namespace = 'headcount';
export default {
  namespace,

  state: {
    loadingList: false,
    loadingUpdate: false,
    loadingCreate: false,
    loadingRemove: false,

    list: [],
    current: null
  },

  effects: {
    *list({ payload }, { call, put, takeEvery, take, select }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/list`,
      });

      yield put({
        type: 'save',
        payload: { loadingList: true },
      });

      // create subscription
      const service = yield call(list, {
        module: namespace,
        orderBy: ['createdAt', 'desc'],
        ...payload,
      });

      function* push(response) {
        let list = null
        if (response.operationType) {
          response.id = response['_id']
          list = yield select(state => state.products.list);
          list = JSON.parse(JSON.stringify(list))

          let index = list.findIndex(x => x.id == response.id)
          if (index > -1) {
            if (response.operationType == 'delete')
              list.splice(index, 1);
            else {
              delete response['operationType']
              list[index] = response
            }
          }
          else {
            delete response['operationType']
            list.push(response)
          }
        }
        else
          list = Array.isArray(response) ? response : [];
        yield put({
          type: 'saveList',
          payload: list,
        });
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/list`);
      service.close();
      yield put({
        type: 'clearList',
      });
    },

    *create({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingCreate: true },
      });

      try {
        const response = yield call(create, {
          module: namespace,
          ...payload,
        });
        if (response) {
          notification.success({
            message: 'Head Counts updated',
          });
          let { headCountsToUpdate, headCountsToCreate } = payload.data
          let cond=headCountsToCreate && headCountsToCreate.length > 0
          if (headCountsToCreate && headCountsToCreate.length > 0) {
            headCountsToCreate.forEach((element) => {
              console.log("element")
              const { lineId, machine, time } = element;
              generateStatsForTime({ productionLineId: lineId, machine, time,statsGenerate:true });
            });
          }

          if (headCountsToUpdate && headCountsToUpdate.length > 0) {
            headCountsToUpdate.forEach((element) => {

              const { lineId, machine, time } = element;
              generateStatsForTime({ productionLineId: lineId, machine, time,statsGenerate:true });


            });
          }


          yield put({
            type: 'save',
            payload: { current: response },
          });

        }
        yield put({
          type: 'save',
          payload: { loadingCreate: false },
        });
      } catch (error) {
        // console.log("error", error);
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingCreate: false },
        });
      }
    },
    *batchCreate({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingCreate: true },
      });

      try {
        const response = yield call(batchCreate, {
          module: namespace,
          ...payload,
        });
        if (response) {
          notification.success({
            message: 'Products added',
          });
        }
        yield put({
          type: 'save',
          payload: { loadingCreate: false },
        });
      } catch (error) {
        // console.log("error", error);
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingCreate: false },
        });
      }
    },
    *update({ payload }, { call, put }) {
      try {
        yield put({
          type: 'save',
          payload: { loadingUpdate: true },
        });
        const response = yield call(update, {
          module: namespace,
          ...payload,
        });
        if (response) {
          notification.success({
            message: 'Product updated',
          });
        }
        yield put({
          type: 'save',
          payload: { loadingUpdate: false },
        });
      } catch (error) {
        console.error('update error', error);
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { loadingUpdate: false },
        });
      }
    },

    *remove({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingRemove: true },
      });

      try {
        const response = yield call(remove, {
          module: namespace,
          ...payload,
        });
        if (response) {
          notification.success({
            message: 'Product deleted',
          });
        }
        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      } catch (error) {
        // console.log("error", error);
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      }
    },
    *batchDelete({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingRemove: true },
      });

      try {
        const response = yield call(batchDelete, {
          module: namespace,
          ...payload,
        });
        if (response) {
          notification.success({
            message: 'Products deleted',
          });
        }
        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      } catch (error) {
        // console.log("error", error);
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      }
    },
    *unsubscribe(_, { put }) {
      yield put({ type: `clear:${namespace}/list` });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
        loadingList: false,
      };
    },
    clearList(state) {
      return {
        ...state,
        list: [],
        loadingList: false,
      };
    },
  },
};