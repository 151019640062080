import config from '../../config';
import socketIOClient from "socket.io-client";
const socket = socketIOClient(config.realtimeInstanceUrl);

socket.on('connect', function () {
    console.log('connected to realtime server...')
    if (window.rooms) {
        window.rooms.map(room => {
            socket.emit('join', room)
        })
    }
});


export { socket };